import _ from 'lodash';

export function postMessage(event, data) {
    const message = { event };

    if (data) {
        const tempData = _.reduce(data, (acc, value, key) => {
            if (!_.isFunction(value) && key !== 'fetchParams') {
                acc[key] = value;
            }

            return acc;
        }, {});

        message.data = tempData.body || tempData;
    }

    if (event === 'formValidations') {
        message.data.isFormValid = Object.values(data).reduce((result, val) => result && val, true);
    }

    if (parent !== window) {
        parent.postMessage(message, '*');
    } else {
        if (event === 'error') {
            console.error(message);
        } else {
            /* eslint-disable no-console */
            console.log(message);
        }
    }
}
