import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'lodash';

import { actions as terminalActions } from '../terminal/terminalActions';
import { isMerchantConfiguredForTerminalNmi, isMerchantConfiguredForTerminalUsaepay } from '../utils/utils';
import ReactTooltip from 'react-tooltip';
import InputComponent from '../formComponents/input/inputComponent';
import Loader from '../shared/loader/Loader';

import './terminalModal.scss';

export class TerminalModal extends React.Component {
    constructor(props) {
        super(props);
        const isConfiguredForUsaepay = isMerchantConfiguredForTerminalUsaepay(props.merchant);
        const isConfiguredForNmi = isMerchantConfiguredForTerminalNmi(props.merchant);
        this.state = {
            terminalName: '',
            registrationCode: '',
            isConfiguredForNmi,
            isConfiguredForUsaepay,
            terminalConnectionMethod: isConfiguredForNmi ? isConfiguredForUsaepay ? '' : 'register' : 'pair',
            checkTerminalList: false,
            pairTerminalTimeout: false,
            terminalPaired: false,
            pairingCodeTerminalKey: '',
            isModalOpen: false
        };

        this.handleTerminalNameChange = this.handleTerminalNameChange.bind(this);
        this.fetchPairingCode = this.fetchPairingCode.bind(this);
    }

    handleTerminalNameChange(terminalName) {
        this.setState({
            terminalName
        });
    }

    handleRegistrationCodeChange = (registrationCode) => {
        this.setState({
            registrationCode
        });
    };

    componentDidMount() {
        this.setState({
            isModalOpen: true
        });
    }

    componentWillUnmount() {
        if (this.state.timeOut) {
            clearTimeout(this.state.timeOut);
        }
        this.setState({
            isModalOpen: false
        });
    }

    componentDidUpdate() {
        if (!this.state.terminalPaired
            && !this.state.pairTerminalTimeout
            && _.get(this.props, 'pairingCodeTerminalKey')
            && _.get(this.props, 'keepCheckingForNewTerminal')) {
            const pairingCodeTerminalKey = _.get(this.props, 'pairingCodeTerminalKey');
            const pairingTerminal = _.find(this.props.terminalList, function (o) {
                return o.terminalKey === pairingCodeTerminalKey;
            });
            if (_.get(pairingTerminal, 'terminalStatus') !== 'connected') {
                this.props.onCheckTerminals();
            } else if (_.get(pairingTerminal, 'terminalStatus') === 'connected') {
                this.setState({
                    terminalPaired: true
                });
            }
        }
    }

    fetchPairingCode() {
        const { fetchPairingCode, merchantId, settings } = this.props;
        const paymentOptionTag = _.get(settings, 'processingOptions.paymentOptionTag', null);
        fetchPairingCode(this.state.terminalName, merchantId, paymentOptionTag);
        this.setState({
            timeOut: setTimeout(() => {
                if (this.state.isModalOpen) {
                    this.setState({
                        pairTerminalTimeout: true
                    });
                }
            }, 60000)
        });
    }

    registerTerminal = () => {
        const { registerTerminal, merchantId, settings } = this.props;
        const paymentOptionTag = _.get(settings, 'processingOptions.paymentOptionTag', null);
        registerTerminal(this.state.terminalName, merchantId, this.state.registrationCode, paymentOptionTag);
        this.setState({
            timeOut: setTimeout(() => {
                if (this.state.isModalOpen) {
                    this.setState({
                        registerTerminalTimeout: true
                    });
                }
            }, 60000)
        });
    };

    handleOnTerminalConnectionMethodChange = (selected) => {
        if (!_.get(this.props, 'isRegisteringTerminal')
            && !_.get(this.props, 'pairingCodeRequestFetching')
            && !_.get(this.props, 'registerTerminalSuccess')
            && !_.get(this.props, 'pairingCode')) {
            this.setState({ terminalConnectionMethod: selected });
        }
    };

    terminalNameTooltipMessage = <div>A terminal nickname to<br />differentiate it from<br />other terminals configured<br />on your account.</div>;

    tooltip = (id, content) => {
        return <ReactTooltip
            id={`${id}`}
            place="bottom"
            type="info"
            effect="float"
            multiline>
            {content}
        </ReactTooltip>;
    };

    renderTerminalConnectionMethodButtons = () => {
        if (this.state.isConfiguredForNmi && this.state.isConfiguredForUsaepay) {
            const tooltipContent = <div>Ensure the terminal is powered on<br />and connected to the WiFi network.<br />Click for troubleshooting</div>;

            return <div>
                <div className={classnames('terminal-connection-method-label', { initial: this.state.terminalConnectionMethod === '' })}>Please check the terminal screen <br /> and select one of the following</div>
                <div className="terminal-connection-method-buttons">
                    <div
                        className={classnames('terminal-connection-method-button register', { selected: this.state.terminalConnectionMethod === 'register', initial: this.state.terminalConnectionMethod === '' })}
                        onClick={() => this.handleOnTerminalConnectionMethodChange('register')}
                    >
                        My terminal shows a <b>registration</b> code
                    </div>
                    <div
                        className={classnames('terminal-connection-method-button pair', { selected: this.state.terminalConnectionMethod === 'pair', initial: this.state.terminalConnectionMethod === '' })}
                        onClick={() => this.handleOnTerminalConnectionMethodChange('pair')}
                    >
                        My terminal is requesting a <b>pairing</b> code
                    </div>
                </div>
                {_.get(this.state, 'terminalConnectionMethod') === '' ?
                    <>
                        <button
                            type="button"
                            className="cancel-button"
                            onClick={this.props.handleClose}
                        >
                            Close
                        </button>
                        <a data-tip data-for="terminal-connection-method" href="https://docs.nexiopay.com/docs/overview" target="_blank" rel="noreferrer">
                            Need help?
                            {this.tooltip('terminal-connection-method', tooltipContent)}
                        </a>
                    </>
                    :
                    null}
            </div>;
        } else {
            return <></>;
        }
    };

    renderTerminalConnectionFlow = () => {
        if (this.state.terminalConnectionMethod === 'pair') {
            return !this.props.pairingCode
                ? this.renderFirstStage()
                : this.renderSecondStage();
        } else if (this.state.terminalConnectionMethod === 'register') {
            return this.renderRegistrationFlow();
        } else {
            return null;
        }
    };

    renderRegistrationFlow = () => {
        const { terminalName, registrationCode } = this.state;
        const { isRegisteringTerminal, registerTerminalSuccess, handleClose, isTerminalListLoaded } = this.props;
        const submitButtonClasses = classnames({
            'submit-button': true,
            'disabled': !terminalName.length || !registrationCode.length || isRegisteringTerminal || !isTerminalListLoaded
        });

        let buttonLabel = 'Submit';
        let modalLabel = 'Enter terminal registration info';

        if (registerTerminalSuccess) {
            buttonLabel = 'Continue to Payment';
            modalLabel = 'Successfully registered terminal';
        }

        return (
            <div className="terminal-name-modal">
                <h4>{modalLabel}</h4>
                <InputComponent
                    name="terminalName"
                    label="Terminal Name"
                    tooltip={this.terminalNameTooltipMessage}
                    value={terminalName}
                    onChange={this.handleTerminalNameChange}
                    disabled={registerTerminalSuccess || isRegisteringTerminal}
                />
                <InputComponent
                    name="registrationCode"
                    label="Registration Code"
                    value={registrationCode}
                    disabled={registerTerminalSuccess || isRegisteringTerminal}
                    onChange={this.handleRegistrationCodeChange}
                />
                <button
                    type="button"
                    className="cancel-button"
                    onClick={handleClose}
                >
                    Close
                </button>
                <button
                    type="button"
                    className={submitButtonClasses}
                    disabled={!terminalName.length || !registrationCode.length || isRegisteringTerminal || !isTerminalListLoaded}
                    onClick={registerTerminalSuccess ? handleClose : this.registerTerminal}
                >
                    {isRegisteringTerminal || !isTerminalListLoaded ? <Loader /> : buttonLabel}
                </button>
            </div>
        );
    };

    renderFirstStage() {
        const { terminalName } = this.state;
        const { pairingCodeRequestFetching, handleClose } = this.props;
        const submitButtonClasses = classnames({
            'submit-button': true,
            'disabled': !terminalName.length || pairingCodeRequestFetching
        });

        return (
            <div className="terminal-name-modal">
                <h4>Enter a terminal name to register</h4>
                <InputComponent
                    name="terminalName"
                    label="Terminal Name"
                    value={terminalName}
                    tooltip={this.terminalNameTooltipMessage}
                    onChange={this.handleTerminalNameChange}
                />
                <button
                    type="button"
                    className="cancel-button"
                    onClick={handleClose}
                >
                    Close
                </button>
                <button
                    type="button"
                    className={submitButtonClasses}
                    disabled={!terminalName.length || pairingCodeRequestFetching}
                    onClick={this.fetchPairingCode}
                >
                    {pairingCodeRequestFetching ? <Loader /> : 'Submit'}
                </button>
            </div>
        );
    }

    renderSecondStage() {
        const { pairingCode, handleClose } = this.props;
        const { pairTerminalTimeout, terminalPaired } = this.state;
        const submitButtonClasses = classnames({
            'submit-button': true,
            'continue-payment': true,
            waiting: !terminalPaired
        });

        let pairingModalLabel = 'Enter pairing code below in your terminal';
        let buttonLabel = 'Cancel';

        if (pairTerminalTimeout) {
            pairingModalLabel = 'The pairing has timed out.';
            buttonLabel = 'Close';
        }
        if (terminalPaired) {
            pairingModalLabel = 'Pairing Complete';
            buttonLabel = 'Continue to Payment';
        }

        let codeBlock = <code className="code-block">
            {pairingCode}
        </code>;

        if (terminalPaired) {
            codeBlock = '';
        }

        return (
            <div className="terminal-name-modal">
                <h4>{pairingModalLabel}</h4>
                {codeBlock}
                <button
                    type="button"
                    className={submitButtonClasses}
                    onClick={handleClose}
                >
                    {!pairTerminalTimeout && !terminalPaired ? <Loader /> : ''}{buttonLabel}
                </button>
            </div>
        );
    }

    render() {
        return (
            <div className="terminal-modal">
                {this.renderTerminalConnectionMethodButtons()}
                {this.renderTerminalConnectionFlow()}
            </div>
        );
    }
}

TerminalModal.propTypes = {
    pairingCodeRequestFetching: PropTypes.bool,
    pairingCode: PropTypes.number,
    pairingCodeTerminalKey: PropTypes.string,
    merchantId: PropTypes.string,
    settings: PropTypes.object,
    fetchPairingCode: PropTypes.func,
    handleClose: PropTypes.func,
    checkTerminalList: PropTypes.bool,
    onCheckTerminals: PropTypes.func,
    terminalList: PropTypes.array,
    merchant: PropTypes.object,
    registerTerminal: PropTypes.func,
    registerTerminalSuccess: PropTypes.bool,
    isRegisteringTerminal: PropTypes.bool,
    isTerminalListLoaded: PropTypes.bool
};

const mapStateToProps = ({ terminal, app, merchant }) => {
    return {
        settings: _.get(app, 'settings', {}),
        merchant: _.find(_.get(merchant, 'merchantList'), { merchantId: app.merchantId }),
        merchantId: app.merchantId,
        pairingCode: terminal.pairingCode,
        pairingCodeTerminalKey: terminal.pairingCodeTerminalKey,
        pairingCodeRequestFetching: terminal.pairingCodeRequestFetching,
        isRegisteringTerminal: terminal.isRegisteringTerminal,
        registerTerminalSuccess: terminal.registerTerminalSuccess,
        keepCheckingForNewTerminal: terminal.keepCheckingForNewTerminal,
        isTerminalListLoaded: terminal.isTerminalListLoaded,
        terminalList: terminal.terminalList
    };
};

function mapDispatchToProps(dispatch) {
    return {
        fetchPairingCode: (terminalName, merchantId, paymentOptionTag) => dispatch(terminalActions.fetchPairingCode(terminalName, merchantId, paymentOptionTag)),
        registerTerminal: (terminalName, merchantId, registrationCode, paymentOptionTag) => dispatch(terminalActions.registerTerminal(terminalName, merchantId, registrationCode, paymentOptionTag)),
        getTerminals: () => dispatch(terminalActions.getTerminals()),
        onCheckTerminals: _.debounce(() => {
            return dispatch(terminalActions.getTerminals());
        }, 1000)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TerminalModal);