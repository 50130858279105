import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { actions as loginActions } from './login/loginActions';
import { actions as appActions } from './appActions';
import { actions as customerVaultActions } from './customerVault/customerVaultActions';
import * as iframeUtils from './utils/iframeUtils';
import LoginComponent from './login/loginComponent';
import ActionSelector from './actionSelector/ActionSelector';
import KeyedComponent from './keyed/keyedComponent';
import TerminalComponent from './terminal/terminalComponent';
import EcheckComponent from './echeck/echeckComponent';

import './app.scss';
import './vendorStyles/toastify.scss';
import './favicon.ico';

export class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isInIframe: parent !== window,
            isLoaded: false,
            title: this.props.isSaveCard ? 'Add New Card' : 'New Payment',
            formUpdateCount: 0,
            formHeight: 0,
            keyedComponentKey: 0,
            echeckComponentKey: 0
        };

        this.autoFocus = this.autoFocus.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);

        this.formRef = React.createRef();
        this.autoFocusAttempts = 0;
    }

    componentDidMount() {
        if (this.props.simpleLoginKey) {
            this.props.simpleLogin(this.props.simpleLoginKey);
        } else {
            this.props.refresh();
        }
        this.props.setupListeners();

        setTimeout(this.autoFocus, 2000);
    }

    componentDidUpdate(prevProps) {
        if (!this.state.isLoaded && this.props.isMerchantListLoaded && this.props.isTerminalListLoaded) {
            this.setState({
                isLoaded: true
            });

            iframeUtils.postMessage('loaded');

            const { customerRef } = this.props;

            if (customerRef) {
                this.props.getCustomerCards(customerRef);
                this.props.getCustomerEchecks(customerRef);
            }
        }

        const formHeight = _.get(this.formRef, 'current.scrollHeight');

        if (formHeight && formHeight !== this.state.formHeight) {
            this.setState(
                { formHeight },
                () => iframeUtils.postMessage('formHeightChange', { formHeight })
            );
        }

        if (prevProps.vaultedCard !== this.props.vaultedCard) {
            this.setState({
                keyedComponentKey: this.state.keyedComponentKey + 1
            });
        }

        if (prevProps.vaultedEcheck !== this.props.vaultedEcheck) {
            this.setState({
                echeckComponentKey: this.state.echeckComponentKey + 1
            });
        }
    }

    autoFocus() {
        this.autoFocusAttempts++;
        let found = false;
        try {
            const selectableElement = this.formRef.current.querySelector('select, input');
            if (selectableElement) {
                selectableElement.focus();
                found = true;
            }
        } catch (e) {
            // ignore
        }

        if (!found && this.autoFocusAttempts < 20) {
            setTimeout(this.autoFocus, 250);
        }
    }

    handleFormChange() {
        this.setState({ formUpdateCount: this.state.formUpdateCount + 1 });
    }

    renderAction() {
        if (this.props.paymentMethod === 'TERMINAL') {
            return <TerminalComponent onFormChange={this.handleFormChange} />;
        } else if (this.props.paymentMethod === 'ECHECK' || (this.props.paymentMethod === 'VAULT' && this.props.vaultedEcheck)) {
            return <EcheckComponent key={this.state.echeckComponentKey} onFormChange={this.handleFormChange} />;
        } else {
            return <KeyedComponent key={this.state.keyedComponentKey} onFormChange={this.handleFormChange} />;
        }
    }

    renderCss() {
        if (this.props.cssFile && /^https:.*\.css$/.test(this.props.cssFile)) {
            return <link rel="stylesheet" type="text/css" href={this.props.cssFile} />;
        }
    }

    render() {
        const classNames = [
            'nexio',
            'retailIframe',
            this.state.isInIframe ? 'inIframe' : ''
        ].join(' ');

        if (!this.props.isLoggedIn) {
            return (
                <div className={classNames + ' loaded'}>
                    {this.renderCss()}
                    <LoginComponent />
                </div>
            );
        } else {
            const formClassNames = [
                classNames,
                this.state.isLoaded ? 'loaded' : 'loading'
            ].join(' ');

            return (
                <div ref={this.formRef} className={formClassNames}>
                    {this.renderCss()}
                    {this.props.isActionCompleted ?
                        <div className="actionResult">{this.props.actionResult}</div>
                        :
                        <Fragment>
                            <div className="iframeTitle">{this.state.title}</div>
                            <div className="form">
                                <ActionSelector onFormChange={this.handleFormChange} />
                                {this.renderAction()}
                            </div>
                        </Fragment>
                    }
                </div>
            );
        }
    }
}

App.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    isMerchantListLoaded: PropTypes.bool,
    merchantList: PropTypes.array,
    isTerminalListLoaded: PropTypes.bool,
    simpleLoginKey: PropTypes.string,
    refresh: PropTypes.func.isRequired,
    simpleLogin: PropTypes.func.isRequired,
    setupListeners: PropTypes.func.isRequired,
    paymentMethod: PropTypes.string.isRequired,
    isSaveCard: PropTypes.bool,
    isActionPending: PropTypes.bool,
    isActionCompleted: PropTypes.bool,
    actionResult: PropTypes.string,
    hideAuthOnly: PropTypes.bool,
    cssFile: PropTypes.string,
    customerRef: PropTypes.string,
    getCustomerCards: PropTypes.func.isRequired,
    getCustomerEchecks: PropTypes.func.isRequired,
    vaultedCard: PropTypes.any,
    vaultedEcheck: PropTypes.any
};

function mapStateToProps({ login, app, merchant, terminal, customerVault }) {
    return {
        isLoggedIn: login.isLoggedIn,
        isMerchantListLoaded: merchant.isMerchantListLoaded,
        merchantList: merchant.merchantList,
        isTerminalListLoaded: terminal.isTerminalListLoaded,
        simpleLoginKey: app.simpleLogin,
        paymentMethod: app.paymentMethod,
        isSaveCard: _.get(app, 'settings.uiOptions.saveCard'),
        hideAuthOnly: _.get(app, 'settings.uiOptions.hideAuthOnly'),
        cssFile: _.get(app, 'settings.uiOptions.css'),
        customerRef: _.get(app, 'settings.data.customer.customerRef'),
        isActionPending: app.isActionPending,
        isActionCompleted: app.isActionCompleted,
        actionResult: app.actionResultMessage,
        vaultedCard: customerVault.card,
        vaultedEcheck: customerVault.echeck
    };
}

export default connect(
    mapStateToProps,
    {
        refresh: loginActions.refresh,
        simpleLogin: loginActions.simpleLogin,
        setupListeners: appActions.setupListeners,
        getCustomerCards: customerVaultActions.getCustomerCards,
        getCustomerEchecks: customerVaultActions.getCustomerEchecks
    }
)(App);
