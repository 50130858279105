import _ from 'lodash';
import qs from 'qs';

const initialState = {
    paymentMethod: 'KEYED',
    isActionCompleted: false,
    isActionPending: false,
    ...loadInitialState(location.search)
};

export function loadInitialState(queryString) {
    const params = qs.parse(queryString, { ignoreQueryPrefix: true });

    const simpleLogin = params.simpleLogin;

    let settings;
    if (params.settings) {
        try {
            settings = JSON.parse(params.settings);
        } catch (e) {
            console.error('unable to parse "settings" json');
        }
    }

    return {
        simpleLogin,
        settings,
        merchantId: _.get(settings, 'uiOptions.merchantIdSet') || _.get(settings, 'uiOptions.merchantIdDefault'),
        currency: _.get(settings, 'data.currency')
    };
}

export const actionTypes = {
    ON_GET_SETTINGS: 'onGetSettings',
    UPDATE_MERCHANT_ID: 'updateMerchantId',
    UPDATE_PAYMENT_METHOD: 'updatePaymentMethod',
    UPDATE_CURRENCY: 'updateCurrency',
    SET_LOADED: 'setLoaded',
    ACTION_PENDING: 'actionPending',
    ACTION_COMPLETED: 'actionCompleted'
};

const eventTypeActionMap = {
    settings: actionTypes.ON_GET_SETTINGS
};

const setupListeners = () => (dispatch) => {
    window.addEventListener('message', (event) => {
        const origin = _.get(event, 'origin');
        const eventType = _.get(event, 'data.event');
        const eventData = _.get(event, 'data.data') || {};

        if (eventType && _.startsWith(document.referrer, origin)) {
            dispatch({
                type: eventTypeActionMap[eventType],
                payload: eventData
            });
        }
    });
};

const updateMerchant = (merchantId) => (dispatch) => {
    dispatch({
        type: actionTypes.UPDATE_MERCHANT_ID,
        payload: merchantId
    });
};

const updatePaymentMethod = (paymentMethod) => (dispatch) => {
    dispatch({
        type: actionTypes.UPDATE_PAYMENT_METHOD,
        payload: paymentMethod
    });
};

const updateCurrency = (currency) => (dispatch) => {
    dispatch({
        type: actionTypes.UPDATE_CURRENCY,
        payload: currency
    });
};

const actionPending = () => (dispatch) => {
    dispatch({ type: actionTypes.ACTION_PENDING });
};

const actionCompleted = (message) => (dispatch) => {
    dispatch({ type: actionTypes.ACTION_COMPLETED, payload: message });
};

export const actions = {
    setupListeners,
    updateMerchant,
    updatePaymentMethod,
    updateCurrency,
    actionPending,
    actionCompleted
};

export function reducers(state = initialState, action) {
    switch (action.type) {
        case actionTypes.ON_GET_SETTINGS:
            return {
                ...state,
                settings: action.payload,
                currency: _.get(action.payload, 'data.currency'),
                merchantId: _.get(action.payload, 'uiOptions.merchantIdSet') || _.get(action.payload, 'uiOptions.merchantIdDefault')
            };
        case actionTypes.UPDATE_MERCHANT_ID:
            return {
                ...state,
                merchantId: action.payload
            };
        case actionTypes.UPDATE_PAYMENT_METHOD:
            return {
                ...state,
                paymentMethod: action.payload
            };
        case actionTypes.UPDATE_CURRENCY:
            return {
                ...state,
                currency: action.payload
            };
        case actionTypes.ACTION_PENDING:
            return {
                ...state,
                isActionPending: true
            };
        case actionTypes.ACTION_COMPLETED:
            return {
                ...state,
                isActionPending: false,
                isActionCompleted: true,
                actionResultMessage: action.payload
            };
        default:
            return state;
    }
}
