import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import './Modal.scss';

class Modal extends React.Component {
    componentDidMount() {
        ReactModal.setAppElement('#root');
    }

    render() {
        const { isOpen, handleClose, style, children } = this.props;

        return (
            <ReactModal
                isOpen={isOpen}
                onRequestClose={handleClose}
                shouldCloseOnOverlayClick={false}
                style={{ content: style }}
            >
                {children}
            </ReactModal>
        );
    }
}

Modal.propTypes = {
    children: PropTypes.node.isRequired,
    id: PropTypes.string,
    isOpen: PropTypes.bool,
    handleClose: PropTypes.func,
    style: PropTypes.shape({
        top: PropTypes.string,
        bottom: PropTypes.string,
        left: PropTypes.string,
        right: PropTypes.string
    })
};

Modal.defaultProps = {
    style: {
        top: 'auto',
        bottom: 'auto',
        left: 'auto',
        right: 'auto'
    }
};

export default Modal;