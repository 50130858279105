import _ from 'lodash';
import moment from 'moment';
import { isValidCreditCard } from '@nexio/emvio-util-app';

export default {
    checkDoesNotContainCreditCardLikeSubstring,
    checkRequired,
    checkGreaterThanZero,
    checkLessThanOrEqualTo,
    checkOnlyTwoDecimalValues
};

export function checkDoesNotContainCreditCardLikeSubstring(value) {
    if (value) {
        const sanitizedValue = String(value);
        const withoutWhitespace = sanitizedValue.replace(/\s/g, '');
        const pattern = /.*\d{12,20}.*/;

        if (pattern.test(withoutWhitespace)) {
            return 'Field cannot contain card number';
        }
    }
}

export function checkRequired(value) {
    value = _.trim(value);

    if (_.isEmpty(value)) {
        return 'Required';
    }
}

export function checkGreaterThanZero(value) {
    const amount = Number(value);
    if (_.isNaN(amount) || amount <= 0) {
        return 'Must be greater than zero';
    }
}

export function checkOnlyTwoDecimalValues(value) {
    const amount = Number(value);
    if (_.isNaN(amount) || _.floor(amount, 2) !== _.floor(amount, 3)) {
        return 'Must not exceed two decimal places (ex. 0.00)';
    }
}

export function checkLessThanOrEqualTo(value, max) {
    const number = Number(value);
    const maxNumber = Number(max);
    if (max && number > maxNumber) {
        return 'Must not exceed ' + max;
    }
}

export function checkAmountLessThanMax(value, amountMax, amountSet) {
    if (amountSet) {
        return;
    }

    return checkLessThanOrEqualTo(value, amountMax);
}

export function checkIsNumber(value) {
    const number = Number(value);
    if (!_.isFinite(number)) {
        return 'Must be a number';
    }
}

export function checkPhone(value) {
    if (!/^[0-9]*$/.test(value)) {
        return 'Must Be A Number';
    } else if (_.size(value) < 5) {
        return 'Invalid Phone Number';
    }
}

export function checkAccountNumber(value) {
    if (!/^[0-9]*$/.test(value)) {
        return 'Must Be A Number';
    } else if (_.size(value) < 4 || _.size(value) > 38) {
        return 'Invalid Account Number';
    }
}

export function checkRoutingNumber(value) {
    if (!/^[0-9]*$/.test(value)) {
        return 'Must Be A Number';
    } else if (![8, 9].includes(_.size(value))) {
        return 'Invalid Routing Number';
    }
}

export function checkDateOfBirth(value) {
    if (_.size(value) !== 10 || !/\d\d\d\d-\d\d-\d\d/.test(value)) {
        return 'Invalid Date of Birth';
    } else {
        const dob = moment(value, 'YYYY-MM-DD');

        if (!dob.isValid() || dob.isAfter(moment())) {
            return 'Invalid Date of Birth';
        }
    }
}

export function checkEmail(value) {
    if (!/.+@.+\..+/.test(value)) {
        return 'Invalid Email';
    }
}

export function checkCardNumber(value) {
    if (_.size(value) >= 15) {
        if (!isValidCreditCard(value)) {
            return 'Invalid Card Number';
        }
    }
}
