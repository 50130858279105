import urlConfig from '@nexio/emvio-url-config';

const config = {
    local: {
        cognito: {
            clientId: '40med6bsrl6uijg2jfg40rbs8i',
            identityPoolId: 'us-east-1:b0d840fd-6f59-4a62-96c8-ad2aa2704d93',
            region: 'us-east-1',
            userPoolId: 'us-east-1_VUD7mLycj'
        },
        tokenex: {
            origin: 'https://test-htp.tokenex.com',
            publicEncryptionKey: 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAvWpIQFjQQCPpaIlJKpeg irp5kLkzLB1AxHmnLk73D3TJbAGqr1QmlsWDBtMPMRpdzzUM7ZwX3kzhIuATV4Pe 7RKp3nZlVmcrT0YCQXBrTwqZNh775z58GP2kZs+gVfNqBampJPzSB/hB62KkByhE Cn6grrRjiAVwJyZVEvs/2vrxaEpO+aE16emtX12RgI5JdzdOiNyZEQteU6zRBRJE ocPWVxExaOpVVVJ5+UnW0LcalzA+lRGRTrQJ5JguAPiAOzRPTK/lYFFpCAl/F8wt oAVG1c8zO2NcQ0Pko+fmeidRFxJ/did2btV+9Mkze3mBphwFmvnxa35LF+Cs/XJHDwIDAQAB'
        }
    },
    test: {
        cognito: {
            clientId: '67a8h88helsmrreneotnfkambi',
            identityPoolId: 'us-east-1:0e34cde6-96b4-4326-85dc-6e45a4324cb1',
            region: 'us-east-1',
            userPoolId: 'us-east-1_jYIRcJqmj'
        },
        tokenex: {
            origin: 'https://test.tokenextest.com',
            publicEncryptionKey: 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAvWpIQFjQQCPpaIlJKpeg irp5kLkzLB1AxHmnLk73D3TJbAGqr1QmlsWDBtMPMRpdzzUM7ZwX3kzhIuATV4Pe 7RKp3nZlVmcrT0YCQXBrTwqZNh775z58GP2kZs+gVfNqBampJPzSB/hB62KkByhE Cn6grrRjiAVwJyZVEvs/2vrxaEpO+aE16emtX12RgI5JdzdOiNyZEQteU6zRBRJE ocPWVxExaOpVVVJ5+UnW0LcalzA+lRGRTrQJ5JguAPiAOzRPTK/lYFFpCAl/F8wt oAVG1c8zO2NcQ0Pko+fmeidRFxJ/did2btV+9Mkze3mBphwFmvnxa35LF+Cs/XJHDwIDAQAB'
        }
    },
    development: {
        cognito: {
            clientId: '40med6bsrl6uijg2jfg40rbs8i',
            identityPoolId: 'us-east-1:b0d840fd-6f59-4a62-96c8-ad2aa2704d93',
            region: 'us-east-1',
            userPoolId: 'us-east-1_VUD7mLycj'
        },
        tokenex: {
            origin: 'https://test-htp.tokenex.com',
            publicEncryptionKey: 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAvWpIQFjQQCPpaIlJKpeg irp5kLkzLB1AxHmnLk73D3TJbAGqr1QmlsWDBtMPMRpdzzUM7ZwX3kzhIuATV4Pe 7RKp3nZlVmcrT0YCQXBrTwqZNh775z58GP2kZs+gVfNqBampJPzSB/hB62KkByhE Cn6grrRjiAVwJyZVEvs/2vrxaEpO+aE16emtX12RgI5JdzdOiNyZEQteU6zRBRJE ocPWVxExaOpVVVJ5+UnW0LcalzA+lRGRTrQJ5JguAPiAOzRPTK/lYFFpCAl/F8wt oAVG1c8zO2NcQ0Pko+fmeidRFxJ/did2btV+9Mkze3mBphwFmvnxa35LF+Cs/XJHDwIDAQAB'
        }
    },
    staging: {
        cognito: {
            clientId: 'g1qln05sad2jcecarsmi2g9ir',
            identityPoolId: 'us-east-1:b0d840fd-6f59-4a62-96c8-ad2aa2704d93',
            region: 'us-east-1',
            userPoolId: 'us-east-1_xJqoFBoS8'
        },
        tokenex: {
            origin: 'https://test-htp.tokenex.com',
            publicEncryptionKey: 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAvWpIQFjQQCPpaIlJKpeg irp5kLkzLB1AxHmnLk73D3TJbAGqr1QmlsWDBtMPMRpdzzUM7ZwX3kzhIuATV4Pe 7RKp3nZlVmcrT0YCQXBrTwqZNh775z58GP2kZs+gVfNqBampJPzSB/hB62KkByhE Cn6grrRjiAVwJyZVEvs/2vrxaEpO+aE16emtX12RgI5JdzdOiNyZEQteU6zRBRJE ocPWVxExaOpVVVJ5+UnW0LcalzA+lRGRTrQJ5JguAPiAOzRPTK/lYFFpCAl/F8wt oAVG1c8zO2NcQ0Pko+fmeidRFxJ/did2btV+9Mkze3mBphwFmvnxa35LF+Cs/XJHDwIDAQAB'
        }
    },
    production: {
        cognito: {
            clientId: '4si89k1m8006t8q8892fefb14e',
            identityPoolId: 'us-east-1:f8399583-f6e8-4ecd-921a-75d87b5a4831',
            region: 'us-east-1',
            userPoolId: 'us-east-1_NqT2EyUjZ'
        },
        tokenex: {
            origin: 'https://htp.tokenex.com',
            publicEncryptionKey: 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA8gGPMwBRPuVyJReZGIkW H/+Bf5pnpDN1bSR2cLYLXVT8CaSnTw74qeboSnktgYCi1D9R3Bj2fYzTIwGGZb8K inLdxwbqZmHwrE9cFhCaHbG/E0PbqQGhXP2vbniZTT4M0i0Cbi7ES3Bw5zqNbIZZ nX041QEpxLvIyWPKZCX+fBogNMhWfCF779aclChjHkwZMsufThVWE9xklWGxXiyt x5aL4I5JPxq0I7cAkZGGs4aF/GxWwPaq7R3wPikJQ0YHnCMfcURzl2Hnw/inqyMy +JB6djTq2/BdzMAhWTh3cDWq9Xu+gEkb/QCd0n1yYIdKuDISlk/AfHdWe34IvzhVyQIDAQAB'
        }
    },
    sandbox: {
        cognito: {
            clientId: '2isqo71nk0jtumglj2sm173ms3',
            identityPoolId: 'us-east-1:f0c46f1b-f34c-450d-a817-f9eaacc00d66',
            region: 'us-east-1',
            userPoolId: 'us-east-1_85ww4iXXR'
        },
        tokenex: {
            origin: 'https://test-htp.tokenex.com',
            publicEncryptionKey: 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAvWpIQFjQQCPpaIlJKpeg irp5kLkzLB1AxHmnLk73D3TJbAGqr1QmlsWDBtMPMRpdzzUM7ZwX3kzhIuATV4Pe 7RKp3nZlVmcrT0YCQXBrTwqZNh775z58GP2kZs+gVfNqBampJPzSB/hB62KkByhE Cn6grrRjiAVwJyZVEvs/2vrxaEpO+aE16emtX12RgI5JdzdOiNyZEQteU6zRBRJE ocPWVxExaOpVVVJ5+UnW0LcalzA+lRGRTrQJ5JguAPiAOzRPTK/lYFFpCAl/F8wt oAVG1c8zO2NcQ0Pko+fmeidRFxJ/did2btV+9Mkze3mBphwFmvnxa35LF+Cs/XJHDwIDAQAB'
        }
    }
};

function getEnv() {
    return process.env.BUILD_ENV || 'test';
}

export default Object.assign(
    {},
    urlConfig[getEnv()],
    config[getEnv()]
);
