import config from '../../config/config';
import { doAction } from '../middleware/fetchMiddleware';
import { actionTypes as appActionTypes } from '../appActions';
import { retailIframeVersion } from '../utils/utils';

const initialState = {};

export const actionTypes = {
    PROCESS_ECHECK: 'processECheck',
    SAVE_ECHECK: 'saveECheck'
};

const processECheck = (transactionBody) => (dispatch) => {
    dispatch({ type: appActionTypes.ACTION_PENDING });

    return dispatch({
        type: doAction.fetch(actionTypes.PROCESS_ECHECK),
        fetchParams: {
            url: config.paymentApiUrl + '/processECheck',
            method: 'post',
            body: transactionBody,
            headers: {
                'X-NEXIO-REQUEST-SOURCE': `retailIframe#${retailIframeVersion}`
            }
        }
    });
};

const saveECheck = (transactionBody) => (dispatch) => {
    dispatch({ type: appActionTypes.ACTION_PENDING });

    return dispatch({
        type: doAction.fetch(actionTypes.SAVE_ECHECK),
        fetchParams: {
            url: config.paymentApiUrl + '/saveECheckAuthed',
            method: 'post',
            body: transactionBody
        }
    });

};

export const actions = {
    processECheck,
    saveECheck
};

export function reducers(state = initialState, action) {
    switch (action.type) {
        case doAction.success(actionTypes.PROCESS_ECHECK):
            return {
                ...initialState,
                paymentSuccess: action.payload
            };
        case doAction.error(actionTypes.PROCESS_ECHECK):
            return {
                ...initialState,
                paymentError: action.payload
            };
        case doAction.success(actionTypes.SAVE_ECHECK):
            return {
                ...initialState,
                saveECheckSuccess: action.payload
            };
        case doAction.error(actionTypes.SAVE_ECHECK):
            return {
                ...initialState,
                saveECheckError: action.payload
            };
        default:
            return state;
    }
}
