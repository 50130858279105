import { combineReducers } from 'redux';
import { reducers as appReducer } from './appActions';
import { reducers as loginReducer, actionTypes } from './login/loginActions';
import { reducers as echeckReducer } from './echeck/echeckActions';
import { reducers as customerVaultReducer } from './customerVault/customerVaultActions';
import { reducers as keyedReducer } from './keyed/keyedActions';
import { reducers as merchantReducer } from './merchant/merchantActions';
import { reducers as terminalReducer } from './terminal/terminalActions';

const rootReducer = combineReducers({
    app: appReducer,
    echeck: echeckReducer,
    customerVault: customerVaultReducer,
    login: loginReducer,
    keyed: keyedReducer,
    merchant: merchantReducer,
    terminal: terminalReducer

});

export default (state, action) => {
    if (action.type === actionTypes.LOGOUT) {
        state = undefined;
    }

    return rootReducer(state, action);
};
