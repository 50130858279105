import _ from 'lodash';
import config from '../../config/config';
import { doAction } from '../middleware/fetchMiddleware';
import tokenexCss from './tokenex.scss?string';
import { actionTypes as appActionTypes } from '../appActions';
import { retailIframeVersion } from '../utils/utils';

const initialState = {};

export const actionTypes = {
    GET_TOKENEX_DATA: 'getTokenexData',
    PROCESS_PAYMENT: 'processPayment',
    SAVE_CARD: 'saveCard'
};

const getTokenexIframeToken = () => (dispatch) => {
    dispatch({
        type: doAction.fetch(actionTypes.GET_TOKENEX_DATA),
        fetchParams: {
            url: config.paymentApiUrl + '/getTokenexIframeToken',
            method: 'post',
            headers: {
                'X-Forwarded-Host': _.replace(window.location.origin, 'https://', '')
            },
            body: JSON.stringify({
                tokenexCss: tokenexCss.toString()
            })
        }
    });
};

const processPayment = (transactionBody) => (dispatch) => {
    dispatch({ type: appActionTypes.ACTION_PENDING });

    return dispatch({
        type: doAction.fetch(actionTypes.PROCESS_PAYMENT),
        fetchParams: {
            url: config.paymentApiUrl + '/process',
            method: 'post',
            body: transactionBody,
            headers: {
                'X-NEXIO-REQUEST-SOURCE': `retailIframe#${retailIframeVersion}`
            }
        }
    });
};

const saveCard = (transactionBody) => (dispatch) => {
    dispatch({
        type: doAction.fetch(actionTypes.SAVE_CARD),
        fetchParams: {
            url: config.paymentApiUrl + '/saveCardAuthed',
            method: 'post',
            body: transactionBody
        }
    });

    dispatch({ type: appActionTypes.ACTION_PENDING });
};

export const actions = {
    getTokenexIframeToken,
    processPayment,
    saveCard
};

export function reducers(state = initialState, action) {
    switch (action.type) {
        case doAction.success(actionTypes.GET_TOKENEX_DATA):
            return {
                ...state,
                tokenexData: action.payload
            };
        case doAction.error(actionTypes.GET_TOKENEX_DATA):
            return {
                ...state,
                tokenexError: action.payload
            };
        case doAction.success(actionTypes.PROCESS_PAYMENT):
            return {
                ...initialState,
                paymentSuccess: action.payload
            };
        case doAction.error(actionTypes.PROCESS_PAYMENT):
            return {
                ...initialState,
                paymentError: action.payload
            };
        case doAction.success(actionTypes.SAVE_CARD):
            return {
                ...initialState,
                saveCardSuccess: action.payload
            };
        case doAction.error(actionTypes.SAVE_CARD):
            return {
                ...initialState,
                saveCardError: action.payload
            };
        default:
            return state;
    }
}
