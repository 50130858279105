import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Check from '../../icons/check';

import './checkBoxComponent.scss';

export class CheckBoxComponent extends Component {
    constructor(props) {
        super(props);

        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.blurHandler = this.blurHandler.bind(this);
        this.focusHandler = this.focusHandler.bind(this);

        const initialValue = props.checked ? props.checked : false;

        this.state = {
            checked: initialValue,
            disabled: props.disabled ? props.disabled : false,
            initialValue
        };

    }

    handleKeyUp(e) {
        e.preventDefault();

        if (e.keyCode === 32) { // space
            this.handleClick();
        }
    }

    handleClick() {
        const { disabled, checked, initalValue } = this.state;

        const newValue = !checked;

        if (!disabled) {
            this.setState({
                checked: newValue,
                isChanged: initalValue !== newValue
            });
            this.props.onClick(newValue);
        }
    }

    focusHandler() {
        this.setState({
            isTouched: true,
            isFocused: true
        });
    }

    blurHandler() {
        this.setState({
            isFocused: false
        });
    }

    getComponentClasses() {
        return [
            'checkBoxComponent',
            this.props.name,
            this.state.isTouched ? 'touched' : 'untouched',
            this.state.isFocused ? 'focused' : 'unfocused',
            this.state.isChanged ? 'changed' : 'unchanged',
            this.props.disabled ? 'disabled' : 'enabled'
        ];
    }

    getCheckClasses() {
        return [
            'input-component-inner',
            this.state.checked ? 'checked' : ''
        ];
    }

    render() {
        const inputProps = {
            ...this.props
        };

        inputProps.labeltext = inputProps.labelText;
        delete inputProps.labelText;

        return (
            <div className={this.getComponentClasses().join(' ')}>
                <label onClick={this.handleClick}>
                    <div className="input-component-label">
                        {this.props.label}
                    </div>
                    <div
                        className={this.getCheckClasses().join(' ')}
                        onKeyUp={this.handleKeyUp}
                        onFocus={this.focusHandler}
                        onBlur={this.blurHandler}
                        title={this.title}
                        tabIndex={this.props.disabled ? -1 : 0}>
                        <div className="check">
                            <Check />
                        </div>
                    </div>
                </label>
            </div>
        );
    }

}

CheckBoxComponent.propTypes = {
    checked: PropTypes.bool,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    invert: PropTypes.bool,
    isVisible: PropTypes.bool,
    label: PropTypes.string,
    onClick: PropTypes.func,
    onEnter: PropTypes.func,
    title: PropTypes.string
};

export default CheckBoxComponent;
