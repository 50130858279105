import _ from 'lodash';
import config from '../../config/config';
import { doAction } from '../middleware/fetchMiddleware';
import { toast } from 'react-toastify';
import { postMessage } from '../utils/iframeUtils';

export const actionTypes = {
    GET_PAYMENT_MERCHANTS: 'getPaymentMerchants',
    GET_REPORTING_MERCHANTS: 'getReportingMerchants'
};

const getMerchants = () => (dispatch) => {
    dispatch({
        type: doAction.fetch(actionTypes.GET_PAYMENT_MERCHANTS),
        fetchParams: {
            url: `${config.paymentApiUrl}/merchant?limit=1000`,
            method: 'get'
        },
        onFail: (action, error) => {
            postMessage('error', {
                ...action,
                message: error
            });
            toast('Unable to fetch Merchants', { type: 'error' });
        }
    });
};

const getReportingMerchants = () => (dispatch) => {
    dispatch({
        type: doAction.fetch(actionTypes.GET_REPORTING_MERCHANTS),
        fetchParams: {
            url: `${config.merchantApiUrl}?limit=1000`,
            method: 'get'
        },
        onFail: (action, error) => {
            postMessage('error', {
                ...action,
                message: error
            });
            toast('Unable to fetch Merchants', { type: 'error' });
        }
    });
};

export const actions = {
    getMerchants,
    getReportingMerchants
};

export function reducers(state = {}, action) {
    switch (action.type) {
        case doAction.fetch(actionTypes.GET_PAYMENT_MERCHANTS):
            return {
                ...state,
                isMerchantListLoaded: false
            };
        case doAction.success(actionTypes.GET_PAYMENT_MERCHANTS):
            return {
                ...state,
                merchantList: _.get(action, 'payload.rows') || [],
                isMerchantListLoaded: true
            };
        case doAction.error(actionTypes.GET_PAYMENT_MERCHANTS):
            return {
                ...state,
                isMerchantListLoaded: true
            };
        case doAction.fetch(actionTypes.GET_REPORTING_MERCHANTS):
            return {
                ...state,
                isReportingMerchantListLoaded: false
            };
        case doAction.success(actionTypes.GET_REPORTING_MERCHANTS):
            return {
                ...state,
                reportingMerchantList: _.get(action, 'payload') || [],
                isReportingMerchantListLoaded: true
            };
        case doAction.error(actionTypes.GET_REPORTING_MERCHANTS):
            return {
                ...state,
                isReportingMerchantListLoaded: true
            };
        default:
            return state;
    }
}
