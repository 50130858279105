import _ from 'lodash';
import { countryList } from '@nexio/emvio-util-app';
import { postMessage } from './iframeUtils';
import { checkRequired } from '../formComponents/input/inputValidators';

export function getValidationError(value, validations = []) {
    const isValueEmpty = _.isNil(value) || value === '';
    const error = _(validations)
        .map((validation) => {
            if (_.isFunction(validation)) {
                if (isValueEmpty && validation !== checkRequired) {
                    return;
                } else {
                    return validation(value);
                }
            }
        })
        .compact()
        .head()
        ;

    if (error) {
        return error;
    } else {
        return '';
    }
}

export function validateAll(fieldsToValidate, softValidate = false) {
    const validationMap = _.mapValues(fieldsToValidate, ({ ref }) => {
        const input = ref.current;
        if (input && input.validate) {
            return input.validate(softValidate);
        }

        return 'unknown';
    });

    postMessage('formValidations', validationMap);

    return _.every(validationMap);
}

export function transformInteger(value) {
    return _.reduce(value, (str, char) => {
        if (/[0-9]/.test(char)) {
            str += char;
        }

        return str;
    }, '');
}

export function transformAmount(value) {
    if (_.isEmpty(value)) {
        return '';
    }

    const decimalCount = _.countBy(value)['.'];
    if (_.isNumber(decimalCount) && decimalCount > 1) {
        // This removes all the . from the string except the first one
        value = value.replace(/(\..*)(\.)/, '$1');
    }

    // only accept digits and decimal
    value = value.replace(/[^\d.]/, '');

    // only allow 3 digits after decimal
    value = value.replace(/(\.\d{3})(\d*)/, '$1');

    if (_.startsWith(value, '.')) {
        value = '0' + value;
    }

    return value;
}

export function transformExpirationDate(value, oldValue) {
    if (value === '00') {
        value = '0';
    }

    if (_.size(value) === 2 && _.size(oldValue) === 3 && _.endsWith(oldValue, '/')) {
        value = value.slice(0, -1);
    }

    if (_.startsWith(value, '1/')) {
        value = `0${value}`;
    }

    const validChars = _.reduce(value, (str, char) => {
        if (/[0-9]/.test(char)) {
            str += char;
        }

        return str;
    }, '');

    return _.reduce(validChars, (str, char, index) => {
        if (index === 0 && /[2-9]/.test(char)) {
            str += 0;
        }

        str += char;

        if (_.size(str) === 2) {
            str += '/';
        }

        return str;
    }, '');
}

export function buildFieldSettingsFromLegacyRequest(uiOptions = {}, formType) {
    const hideBilling = _.get(uiOptions, 'hideBilling');
    // legacy defaults`
    const hideShipping = _.has(uiOptions, 'hideShipping') ? uiOptions.hideShipping : true;
    const hideOrderInfo = _.has(uiOptions, 'hideOrderInfo') ? uiOptions.hideOrderInfo : true;
    const hideCustomerInfo = _.has(uiOptions, 'hideCustomerInfo') ? uiOptions.hideCustomerInfo : true;
    const hidePaymentType = _.has(uiOptions, 'hidePaymentType') ? uiOptions.hidePaymentType : true;

    let fieldSettings = {
        billToCountry: !hideBilling || (_.isObject(hideBilling) && !hideBilling.hideCountry) ? 'required' : null,
        billToAddressOne: !hideBilling || (_.isObject(hideBilling) && !hideBilling.hideAddressOne) ? 'required' : null,
        billToAddressTwo: !hideBilling || (_.isObject(hideBilling) && !hideBilling.hideAddressTwo) ? 'optional' : null,
        billToCity: !hideBilling || (_.isObject(hideBilling) && !hideBilling.hideCity) ? 'required' : null,
        billToState: !hideBilling || (_.isObject(hideBilling) && !hideBilling.hideState) ? 'required' : null,
        billToPostal: !hideBilling || (_.isObject(hideBilling) && !hideBilling.hidePostal) ? 'required' : null,
        billToPhone: !hideBilling || (_.isObject(hideBilling) && !hideBilling.hidePhone) ? 'required' : null,

        shipToCountry: !hideShipping || (_.isObject(hideShipping) && !hideShipping.hideCountry) ? 'required' : null,
        shipToAddressOne: !hideShipping || (_.isObject(hideShipping) && !hideShipping.hideAddressOne) ? 'required' : null,
        shipToAddressTwo: !hideShipping || (_.isObject(hideShipping) && !hideShipping.hideAddressTwo) ? 'optional' : null,
        shipToCity: !hideShipping || (_.isObject(hideShipping) && !hideShipping.hideCity) ? 'required' : null,
        shipToState: !hideShipping || (_.isObject(hideShipping) && !hideShipping.hideState) ? 'required' : null,
        shipToPostal: !hideShipping || (_.isObject(hideShipping) && !hideShipping.hidePostal) ? 'required' : null,
        shipToPhone: !hideShipping || (_.isObject(hideShipping) && !hideShipping.hidePhone) ? 'required' : null,

        invoice: !hideOrderInfo || (_.isObject(hideOrderInfo) && !hideOrderInfo.hideInvoice) ? 'required' : null,
        orderNumber: !hideOrderInfo || (_.isObject(hideOrderInfo) && !hideOrderInfo.hideOrderNumber) ? 'required' : null,
        description: !hideOrderInfo || (_.isObject(hideOrderInfo) && !hideOrderInfo.hideDescription) ? 'required' : null,
        descriptor: !hideOrderInfo || (_.isObject(hideOrderInfo) && !hideOrderInfo.hideDescriptor) ? 'required' : null,

        firstName: !hideCustomerInfo || (_.isObject(hideCustomerInfo) && !hideCustomerInfo.hideFirstName) ? 'required' : null,
        lastName: !hideCustomerInfo || (_.isObject(hideCustomerInfo) && !hideCustomerInfo.hideLastName) ? 'required' : null,
        phone: !hideCustomerInfo || (_.isObject(hideCustomerInfo) && !hideCustomerInfo.hidePhone) ? 'required' : null,
        email: !hideCustomerInfo || (_.isObject(hideCustomerInfo) && !hideCustomerInfo.hideEmail) ? 'required' : null,
        customerRef: !hideCustomerInfo || (_.isObject(hideCustomerInfo) && !hideCustomerInfo.hideCustomerRef) ? 'required' : null,
        birthDate: !hideCustomerInfo || (_.isObject(hideCustomerInfo) && !hideCustomerInfo.hideBirthDate) ? 'required' : null,
        nationalIdentificationNumber: !hideCustomerInfo || (_.isObject(hideCustomerInfo) && !hideCustomerInfo.hideNationalIdentificationNumber) ? 'required' : null,

        paymentType: !hidePaymentType ? 'required' : null
    };

    if (formType === 'card') {
        const hideAuthOnly = _.has(uiOptions, 'hideAuthOnly') ? uiOptions.hideAuthOnly : true;
        const hideCardInfo = _.has(uiOptions, 'hideCardInfo') ? uiOptions.hideCardInfo : true;

        fieldSettings = {
            ...fieldSettings,

            isAuthOnly: hideAuthOnly ? null : 'required',

            securityCode: 'required',
            classification: hideCardInfo && !_.get(hideCardInfo, 'hideClassification') ? null : 'required',
            businessNumber: hideCardInfo && !_.get(hideCardInfo, 'hideBusinessNumber') ? null : 'required',
            password: hideCardInfo && !_.get(hideCardInfo, 'hidePassword') ? null : 'required'
        };

    }

    return fieldSettings;
}

export function getCountryByCountryCode(countryCode) {
    return countryList.find((country) => country.code === countryCode);
}

export function getCountryOptions(limitCountriesTo = []) {
    let countries = countryList;
    if (!_.isEmpty(limitCountriesTo)) {
        countries = _.intersectionWith(countries, limitCountriesTo, (country, limitedCountryCode) => {
            return country.code === limitedCountryCode;
        });
    }

    return countries.map((country) => {
        return {
            value: country.code,
            name: country.name
        };
    });
}

export function getSectionLabel(section) {
    switch (section) {
        case 'cardInfo':
            return 'Card Information';
        case 'echeckInfo':
            return 'eCheck Information';
        case 'customerInfo':
            return 'Customer Information';
        case 'orderInfo':
            return 'Order Information';
        case 'billingInfo':
            return 'Billing Information';
        case 'shippingInfo':
            return 'Shipping Information';
        default:
            return '';
    }
}

export function renderSections(formFields, sectionRenderer) {
    const sections = _.reduce(formFields, (acc, field, fieldName) => {
        const section = field.section;
        const sectionLabel = getSectionLabel(section);

        const fields = {
            ..._.get(acc, `${section}.fields`),
            [fieldName]: field
        };

        acc[section] = {
            label: sectionLabel,
            fields
        };

        return acc;
    }, {});

    return _.map(sections, sectionRenderer);
}

export const paymentTypes = [
    {
        value: 'initialScheduled',
        name: 'Initial Scheduled'
    },
    {
        value: 'initialUnscheduled',
        name: 'Initial Unscheduled'
    },
    {
        value: 'initialMoto',
        name: 'Initial MOTO'
    },
    {
        value: 'scheduled',
        name: 'Scheduled'
    },
    {
        value: 'unscheduledMit',
        name: 'Unscheduled MIT'
    }
];
