import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { doesPropExistAndHasItChanged, gatewayType } from '@nexio/emvio-util-app';

import SelectComponent from '../formComponents/select/selectComponent';
import InputComponent from '../formComponents/input/inputComponent';
import { actions } from './terminalActions';
import { actions as appActions } from '../appActions';
import { actions as terminalActions } from '../terminal/terminalActions';
import { postMessage } from '../utils/iframeUtils';
import inputValidators from '../formComponents/input/inputValidators';
import { transformAmount } from '../utils/formUtils';
import RefreshIcon from './refreshIcon';
import Modal from '../shared/modal/Modal';
import TerminalModal from './terminalModal';

import './terminalComponent.scss';
import CheckBoxComponent from '../formComponents/input/checkBoxComponent';
import AddIcon from './addIcon';
import { findPossibleGatewayTypes, isMerchantConfiguredForTerminalNmi } from '../utils/utils';
import classnames from 'classnames';

export class TerminalComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            terminal: localStorage.getItem(`default-terminal-${this.props.merchantId}`),
            isAuthOnly: false,
            isModalOpen: false
        };

        this.messageListener = this.messageListener.bind(this);
        this.renderAmountField = this.renderAmountField.bind(this);
        this.renderTerminals = this.renderTerminals.bind(this);
        this.renderButton = this.renderButton.bind(this);
        this.onSubmitForm = this.onSubmitForm.bind(this);
        this.onTerminalChange = this.onTerminalChange.bind(this);
        this.validateAll = this.validateAll.bind(this);
        this.onChangeValidate = this.onChangeValidate.bind(this);
        this.checkAmountLessThanMax = this.checkAmountLessThanMax.bind(this);
        this.refreshTerminalsList = this.refreshTerminalsList.bind(this);
        this.onChangeAuthOnly = this.onChangeAuthOnly.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleModalOpen = this.handleModalOpen.bind(this);

        this.refList = {
            amount: React.createRef(),
            terminal: React.createRef(),
            submitButton: React.createRef()
        };

        window.addEventListener('message', this.messageListener);
    }

    componentDidUpdate(prevProps) {
        if (this.props.terminalRequest && !this.props.terminalRequestFetching && (this.props.terminalRequestStatus === 'pending' || this.props.terminalRequestStatus === 'initialized')) {
            setTimeout(() => {
                this.props.checkTerminalRequest(this.props.terminalRequest.terminalRequestId);
            }, 1000);
        }

        if (doesPropExistAndHasItChanged(prevProps, this.props, 'terminalRequestStatus')) {
            if (this.props.terminalRequestStatus === 'failed') {
                postMessage('error', this.props.terminalRequest);
                this.props.actionCompleted('Payment failed!');
            } else if (_.get(this.props, 'terminalRequest.gatewayResponse.result') === 'Error') {
                postMessage('error', this.props.terminalRequest);
                this.props.actionCompleted('Payment failed!');
            } else if (this.props.terminalRequestStatus === 'success') {
                postMessage('success', this.props.terminalRequest);
                this.props.actionCompleted('Payment was successful!');
            }
        }

        if (doesPropExistAndHasItChanged(prevProps, this.props, 'merchantId')) {
            this.setState({
                terminal: localStorage.getItem(`default-terminal-${this.props.merchantId}`)
            });
        }

        this.props.onFormChange();
    }

    refreshTerminalsList() {
        this.props.getTerminals();
    }

    messageListener(event) {
        if (event.data === 'posted') {
            this.refList.submitButton.current.click();
        }
    }

    validateAll(softValidate) {
        const validationMap = _.mapValues(this.refList, (ref) => {
            const input = ref.current;
            if (input && input.validate) {
                return input.validate(softValidate);
            }

            return 'unknown';
        });

        postMessage('formValidations', validationMap);

        return _.every(validationMap);
    }

    onChangeValidate() {
        this.validateAll(true);
    }

    onSubmitForm(event) {
        event.preventDefault();

        const isValid = this.validateAll();

        if (!isValid) {
            this.setState({
                hasSubmitError: true
            });

            return;
        } else {
            this.setState({
                hasSubmitError: false
            });
        }

        const { amount } = event.target.elements;

        const terminalRequestBody = _.cloneDeep(this.props.settings || {});
        _.set(terminalRequestBody, 'terminalId', this.state.terminal);
        _.set(terminalRequestBody, 'isAuthOnly', this.state.isAuthOnly);
        _.set(terminalRequestBody, 'data.amount', parseFloat(amount.value));
        _.set(terminalRequestBody, 'data.currency', this.props.currency);

        this.props.createTerminalRequest(terminalRequestBody, this.props.merchantId);

    }

    onTerminalChange(terminal) {
        this.setState({ terminal });
    }

    onChangeAuthOnly(isChecked) {
        this.setState({
            isAuthOnly: isChecked
        });
    }

    checkAmountLessThanMax(value) {
        const { amountMax, amountSet } = _.get(this.props, 'settings.uiOptions', {});

        if (amountSet) {
            return;
        }

        return inputValidators.checkLessThanOrEqualTo(value, amountMax);
    }

    handleModalClose() {
        const {
            deletePairingCode,
            pairingCode,
            pairingCodeTerminalKey,
            registeredTerminalKey,
            resetRegisterTerminal
        } = this.props;

        if (pairingCode) {
            deletePairingCode();
        }
        if (pairingCodeTerminalKey) {
            const pairingTerminal = _.find(this.props.terminalList, function (o) {
                return o.terminalKey === pairingCodeTerminalKey;
            });
            if (_.get(pairingTerminal, 'terminalStatus') === 'connected') {
                this.setState({
                    terminal: pairingTerminal.terminalId
                });
            }
        } else if (registeredTerminalKey) {
            resetRegisterTerminal();

            const registeredTerminal = _.find(this.props.terminalList, (terminal) => {
                return terminal.deviceId === registeredTerminalKey;
            });

            if (registeredTerminal) {
                this.setState({
                    terminal: registeredTerminal.terminalId
                });
            }
        }
        this.setState({
            isModalOpen: false
        });
    }

    handleModalOpen() {
        this.setState({
            isModalOpen: true
        });
    }

    renderAmountField() {
        let value = 0;
        let isDisabled = false;

        const { amountSet, amountDefault } = _.get(this.props, 'settings.uiOptions', {});

        if (amountSet) {
            isDisabled = true;
            value = amountSet;
        } else if (amountDefault) {
            value = amountDefault;
        }

        return <InputComponent
            className="amountInput"
            ref={this.refList.amount}
            name="amount"
            label="Amount"
            value={value}
            disabled={this.props.isActionPending || isDisabled}
            validations={[
                inputValidators.checkRequired,
                inputValidators.checkGreaterThanZero,
                inputValidators.checkOnlyTwoDecimalValues,
                this.checkAmountLessThanMax
            ]}
            transformData={transformAmount}
        />;
    }

    renderAuthOnlyField() {
        const shouldShowAuthOnly = _.get(this.props, 'settings.uiOptions.hideAuthOnly') === false;

        if (shouldShowAuthOnly) {
            return <CheckBoxComponent
                checked={this.state.isAuthOnly}
                name="authonly"
                label="Auth Only"
                onClick={this.onChangeAuthOnly}
            />;
        }
    }

    renderTerminals() {
        const { terminalList, merchantId, merchantList, isActionPending } = this.props;

        let possibleGatewayTypes = findPossibleGatewayTypes(merchantId, 'USD', merchantList);

        if (_.includes(possibleGatewayTypes, gatewayType.nmi)) {
            const merchant = _.find(merchantList, { merchantId: merchantId }) || {};

            if (!isMerchantConfiguredForTerminalNmi(merchant)) {
                possibleGatewayTypes = _.without(possibleGatewayTypes, gatewayType.nmi);
            }
        }

        const shouldShowAddTerminalButton = _.size(_.intersection(possibleGatewayTypes, [gatewayType.usaepay, gatewayType.nmi])) > 0;

        const addTerminalButtonClasses = classnames('terminal-add-terminal', {
            'disabled': isActionPending
        });

        if (terminalList) {
            const options = terminalList.filter((terminal) => {
                return terminal.merchantId === merchantId;
            }).map((terminal) => {
                const name = `${terminal.terminalName} - ${terminal.terminalSerialNumber}`;

                return { value: terminal.terminalId, name };
            });

            return (
                <section>
                    <div className="terminal-list-section">
                        <span className="terminal-list-left">
                            <SelectComponent
                                className="terminalSelect"
                                ref={this.refList.terminal}
                                options={options}
                                name="Terminal"
                                label="Terminal"
                                value={this.state.terminal || ''}
                                onChange={this.onTerminalChange}
                                disabled={isActionPending}
                                validations={[inputValidators.checkRequired]}
                            />
                        </span>
                        <RefreshIcon onClick={this.refreshTerminalsList} />
                    </div>
                    {shouldShowAddTerminalButton
                        ? <span className={addTerminalButtonClasses} onClick={this.handleModalOpen}>
                            <AddIcon />
                            Add Terminal
                        </span>
                        : null
                    }
                </section>
            );
        }

        return null;
    }

    renderButton() {
        const { terminalRequestStatus, terminalRequestPendingCount } = this.props;

        const periodWidthSpace = '\u2008';
        const pendingString = `${_.repeat(periodWidthSpace, terminalRequestPendingCount)}Pending${_.repeat('.', terminalRequestPendingCount)}`;

        const buttonText = terminalRequestStatus ? pendingString : 'Submit';

        return (
            <div>
                <button
                    className="submitButton"
                    type="submit"
                    ref={this.submitButtonRef}
                    disabled={this.props.isActionPending}
                >
                    {buttonText}
                </button>
                <span className="submitErrorMessage">{this.state.hasSubmitError ? 'Required field(s) missing or incorrect' : ''}</span>
            </div>
        );
    }

    renderModal() {
        if (!this.props.isActionPending) {
            return (
                <Modal
                    isOpen={this.state.isModalOpen}
                    handleClose={this.handleModalClose}
                >
                    <TerminalModal handleClose={this.handleModalClose} />
                </Modal>
            );
        }
    }

    render() {
        return (
            <form className="terminal" onSubmit={this.onSubmitForm}>
                {this.renderAmountField()}
                {this.renderAuthOnlyField()}
                {this.renderTerminals()}
                {this.renderButton()}
                {this.renderModal()}
            </form>
        );
    }
}

TerminalComponent.propTypes = {
    pairingCode: PropTypes.number,
    createTerminalRequest: PropTypes.func.isRequired,
    checkTerminalRequest: PropTypes.func.isRequired,
    terminalList: PropTypes.array,
    terminalRequest: PropTypes.object,
    terminalRequestStatus: PropTypes.string,
    terminalRequestPendingCount: PropTypes.number,
    terminalRequestFetching: PropTypes.bool,
    settings: PropTypes.object,
    location: PropTypes.any,
    merchantId: PropTypes.string,
    isActionPending: PropTypes.bool,
    actionCompleted: PropTypes.func.isRequired,
    getTerminals: PropTypes.func,
    deletePairingCode: PropTypes.func,
    isAuthOnly: PropTypes.bool,
    currency: PropTypes.string,
    onFormChange: PropTypes.func.isRequired,
    merchantList: PropTypes.array,
    pairingCodeTerminalKey: PropTypes.string,
    registeredTerminalKey: PropTypes.string,
    resetRegisterTerminal: PropTypes.func
};

const mapStateToProps = ({ terminal, app, merchant }) => {
    return {
        terminalList: terminal.terminalList,
        terminalRequest: terminal.terminalRequest,
        terminalRequestStatus: terminal.terminalRequestStatus,
        terminalRequestPendingCount: terminal.terminalRequestPendingCount,
        terminalRequestFetching: terminal.terminalRequestFetching,
        pairingCode: terminal.pairingCode,
        settings: _.get(app, 'settings', {}),
        merchantId: app.merchantId,
        isActionPending: app.isActionPending,
        currency: _.get(app, 'settings.data.currency'),
        merchantList: merchant.merchantList,
        pairingCodeTerminalKey: terminal.pairingCodeTerminalKey,
        registeredTerminalKey: terminal.registeredTerminalKey
    };
};

export default connect(mapStateToProps, {
    createTerminalRequest: actions.createTerminalRequest,
    checkTerminalRequest: actions.checkTerminalRequest,
    actionCompleted: appActions.actionCompleted,
    resetRegisterTerminal: terminalActions.resetRegisterTerminal,
    getTerminals: terminalActions.getTerminals,
    deletePairingCode: terminalActions.deletePairingCode
})(TerminalComponent);
