import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';

import {
    doesPropExistAndHasItChanged,
    currency as currencyMapper,
    gatewayType,
    determineCardType
} from '@nexio/emvio-util-app';

import {
    buildFieldSettingsFromLegacyRequest,
    getCountryByCountryCode,
    getCountryOptions,
    paymentTypes,
    renderSections,
    transformAmount,
    transformExpirationDate,
    transformInteger,
    validateAll
} from '../utils/formUtils';
import {
    encryptValueForTokenex,
    findPossibleGatewayTypes,
    setIf,
    updateTransactionBodyWithFormData
} from '../utils/utils';
import { postMessage } from '../utils/iframeUtils';
import { actions } from './keyedActions';
import { actions as appActions } from '../appActions';
import InputComponent from '../formComponents/input/inputComponent';
import {
    checkDoesNotContainCreditCardLikeSubstring,
    checkGreaterThanZero,
    checkOnlyTwoDecimalValues,
    checkPhone,
    checkRequired,
    checkAmountLessThanMax,
    checkEmail,
    checkDateOfBirth,
    checkCardNumber
} from '../formComponents/input/inputValidators';
import SelectComponent from '../formComponents/select/selectComponent';
import CheckBoxComponent from '../formComponents/input/checkBoxComponent';

import './keyedComponent.scss';

export class KeyedComponent extends Component {
    constructor(props) {
        super(props);

        const { settings, paymentMethod } = props;

        const { amountSet, amountDefault, amountMax, limitCountriesTo } = settings.uiOptions || {};

        let amount = '';
        if (amountSet) {
            amount = amountSet;
        } else if (amountDefault) {
            amount = amountDefault;
        }

        const combinedSettings = _.merge({}, settings, this.props.vaultedCard);
        const paymentTypeFromSettings = _.get(settings, 'processingOptions.paymentType');
        const paymentType = _.find(paymentTypes, (paymentTypeOption) => paymentTypeOption.value === paymentTypeFromSettings) ? paymentTypeFromSettings : 'scheduled';

        this.state = {
            ...(paymentMethod === 'VAULT' ? {
                paymentType: {
                    section: 'paymentInfo',
                    ref: React.createRef(),
                    label: 'Payment Type',
                    type: 'select',
                    options: paymentTypes,
                    value: paymentType,
                    onChange: this.onPaymentTypeChange,
                    validations: [checkRequired],
                    requestParameterPath: 'processingOptions.paymentType'
                }
            } : { paymentType: {} }),
            isShippingInfoSameAsBilling: false,
            amount: {
                section: 'paymentInfo',
                ref: React.createRef(),
                label: 'Amount',
                value: amount,
                requestParameterPath: 'data.amount',
                onChange: this.onAmountChange,
                transformData: transformAmount,
                validations: [
                    checkDoesNotContainCreditCardLikeSubstring,
                    checkGreaterThanZero,
                    checkOnlyTwoDecimalValues,
                    checkRequired,
                    (value) => checkAmountLessThanMax(value, amountMax, amountSet)
                ],
                isDisabled: !!amountSet
            },
            authOnly: {
                section: 'paymentInfo',
                ref: React.createRef(),
                type: 'checkbox',
                label: 'Auth Only',
                value: !!_.get(this.props, 'isAuthOnly'),
                validations: [() => true],
                requestParameterPath: 'isAuthOnly',
                onClick: this.onAuthOnlyChange
            },
            cardHolderName: {
                section: 'cardInfo',
                label: 'Name (as it appears on card)',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'card.cardHolderName'),
                validations: [checkRequired],
                requestParameterPath: 'card.cardHolderName'
            },
            cardNumber: {
                section: 'cardInfo',
                label: 'Card Number',
                ref: React.createRef(),
                value: '',
                validations: [checkRequired, checkCardNumber],
                transformData: transformInteger
            },
            expirationDate: {
                section: 'cardInfo',
                label: 'Expiration Date',
                ref: React.createRef(),
                placeholder: 'MM/YY',
                maxLength: 5,
                value: '',
                validations: [checkRequired, this.checkExpirationDate],
                transformData: transformExpirationDate
            },
            securityCode: {
                section: 'cardInfo',
                label: 'Security Code',
                ref: React.createRef(),
                requestParameterPath: 'card.securityCode',
                validations: [this.checkSecurityCode]
            },
            cardClassification: {
                section: 'cardInfo',
                type: 'select',
                label: 'Classification',
                ref: React.createRef(),
                options: [
                    { name: 'Personal', value: 'personal' },
                    { name: 'Business', value: 'business' }
                ],
                value: _.get(combinedSettings, 'card.classification'),
                requestParameterPath: 'card.classification',
                validations: [],
                onChange: this.onCardClassificationChange
            },
            businessNumber: {
                section: 'cardInfo',
                label: 'Business Number',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'card.businessNumber'),
                validations: [],
                requestParameterPath: 'card.businessNumber'
            },
            password: {
                section: 'cardInfo',
                label: 'Passcode',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'card.password'),
                validations: [],
                requestParameterPath: 'card.password'
            },
            billingCountry: {
                section: 'billingInfo',
                type: 'select',
                label: 'Country',
                ref: React.createRef(),
                options: getCountryOptions(limitCountriesTo),
                value: _.get(combinedSettings, 'data.customer.billToCountry'),
                validations: [],
                requestParameterPath: 'data.customer.billToCountry',
                onChange: (value) => this.onCountryChange(value, 'billingCountry')
            },
            billingAddressOne: {
                section: 'billingInfo',
                label: 'Address 1',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'data.customer.billToAddressOne'),
                validations: [],
                requestParameterPath: 'data.customer.billToAddressOne'
            },
            billingAddressTwo: {
                section: 'billingInfo',
                label: 'Address 2',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'data.customer.billToAddressTwo'),
                validations: [],
                requestParameterPath: 'data.customer.billToAddressTwo'
            },
            billingCity: {
                section: 'billingInfo',
                label: 'City',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'data.customer.billToCity'),
                validations: [],
                requestParameterPath: 'data.customer.billToCity'
            },
            billingState: {
                section: 'billingInfo',
                type: 'select',
                label: 'State / Province',
                ref: React.createRef(),
                options: [],
                value: _.get(combinedSettings, 'data.customer.billToState'),
                validations: [],
                requestParameterPath: 'data.customer.billToState'
            },
            billingPostalCode: {
                section: 'billingInfo',
                label: 'Postal Code',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'data.customer.billToPostal'),
                validations: [],
                requestParameterPath: 'data.customer.billToPostal'
            },
            billingPhone: {
                section: 'billingInfo',
                label: 'Phone',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'data.customer.billToPhone'),
                requestParameterPath: 'data.customer.billToPhone',
                validations: [checkPhone],
                transformData: transformInteger
            },
            shippingCountry: {
                section: 'shippingInfo',
                type: 'select',
                label: 'Country',
                ref: React.createRef(),
                options: getCountryOptions(limitCountriesTo),
                value: _.get(combinedSettings, 'data.customer.shipToCountry'),
                validations: [],
                requestParameterPath: 'data.customer.shipToCountry',
                onChange: (value) => this.onCountryChange(value, 'shippingCountry')
            },
            shippingAddressOne: {
                section: 'shippingInfo',
                label: 'Address 1',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'data.customer.shipToAddressOne'),
                validations: [],
                requestParameterPath: 'data.customer.shipToAddressOne'
            },
            shippingAddressTwo: {
                section: 'shippingInfo',
                label: 'Address 2',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'data.customer.shipToAddressTwo'),
                validations: [],
                requestParameterPath: 'data.customer.shipToAddressTwo'
            },
            shippingCity: {
                section: 'shippingInfo',
                label: 'City',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'data.customer.shipToCity'),
                validations: [],
                requestParameterPath: 'data.customer.shipToCity'
            },
            shippingState: {
                section: 'shippingInfo',
                type: 'select',
                label: 'State / Province',
                ref: React.createRef(),
                options: [],
                value: _.get(combinedSettings, 'data.customer.shipToState'),
                validations: [],
                requestParameterPath: 'data.customer.shipToState'
            },
            shippingPostalCode: {
                section: 'shippingInfo',
                label: 'Postal Code',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'data.customer.shipToPostal'),
                validations: [],
                requestParameterPath: 'data.customer.shipToPostal'
            },
            shippingPhone: {
                section: 'shippingInfo',
                label: 'Phone',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'data.customer.shipToPhone'),
                requestParameterPath: 'data.customer.shipToPhone',
                validations: [checkPhone],
                transformData: transformInteger
            },
            invoice: {
                section: 'orderInfo',
                label: 'Invoice',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'data.customer.invoice'),
                validations: [],
                requestParameterPath: 'data.customer.invoice'
            },
            orderNumber: {
                section: 'orderInfo',
                label: 'Order Number',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'data.customer.orderNumber'),
                validations: [],
                requestParameterPath: 'data.customer.orderNumber'
            },
            description: {
                section: 'orderInfo',
                label: 'Description',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'data.description'),
                validations: [],
                requestParameterPath: 'data.description'
            },
            descriptor: {
                section: 'orderInfo',
                label: 'Descriptor',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'data.descriptor'),
                validations: [],
                requestParameterPath: 'data.descriptor'
            },
            orderDate: {
                section: 'orderInfo',
                label: 'Order Date',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'data.customer.orderDate'),
                type: 'date',
                validations: [],
                requestParameterPath: 'data.customer.orderDate'
            },
            firstName: {
                section: 'customerInfo',
                label: 'First Name',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'data.customer.firstName'),
                validations: [],
                requestParameterPath: 'data.customer.firstName'
            },
            lastName: {
                section: 'customerInfo',
                label: 'Last Name',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'data.customer.lastName'),
                validations: [],
                requestParameterPath: 'data.customer.lastName'
            },
            phone: {
                section: 'customerInfo',
                label: 'Phone',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'data.customer.phone'),
                validations: [checkPhone],
                transformData: transformInteger,
                requestParameterPath: 'data.customer.phone'
            },
            email: {
                section: 'customerInfo',
                label: 'Email',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'data.customer.email'),
                validations: [checkEmail],
                requestParameterPath: 'data.customer.email'
            },
            customerRef: {
                section: 'customerInfo',
                label: 'Customer Reference Number',
                ref: React.createRef(),
                value: _.get(this.props, 'settings.data.customer.customerRef'),
                validations: [],
                requestParameterPath: 'data.customer.customerRef',
                isDisabled: this.props.paymentMethod === 'VAULT'
            },
            birthDate: {
                section: 'customerInfo',
                label: 'Date of Birth',
                placeholder: 'YYYY-MM-DD',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'data.customer.birthDate'),
                validations: [checkDateOfBirth],
                requestParameterPath: 'data.customer.birthDate'
            },
            nationalIdentificationNumber: {
                section: 'customerInfo',
                label: 'National Identification Number',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'data.customer.nationalIdentificationNumber'),
                validations: [],
                requestParameterPath: 'data.customer.nationalIdentificationNumber'
            }
        };

        this.submitButtonRef = React.createRef();
    }

    async componentDidMount() {
        window.addEventListener('message', this.messageListener);

        await this.updateFormFields();

        if (this.state.billingCountry.shouldRender || this.state.billingState.shouldRender) {
            await this.onCountryChange(this.state.billingCountry.value, 'billingCountry', true);
        }

        if (this.state.shippingCountry.shouldRender || this.state.shippingState.shouldRender) {
            await this.onCountryChange(this.state.shippingCountry.value, 'shippingCountry', true);
        }

        this.onCardClassificationChange(this.state.cardClassification.value);
    }

    componentDidUpdate(prevProps) {
        const shouldHideToast = _.get(this.props, 'settings.uiOptions.shouldHideToast');

        if (doesPropExistAndHasItChanged(prevProps, this.props, 'currency')) {
            this.updateFormFields();
        }

        if (doesPropExistAndHasItChanged(prevProps, this.props, 'paymentMethod')) {
            if (this.props.paymentMethod !== 'VAULT') {
                this.setState({ paymentType: {} }, () => {
                    this.updateFormFields();
                });
            } else {
                const paymentTypeFromSettings = _.get(this.props, 'settings.processingOptions.paymentType');
                const paymentTypeValue = _.find(paymentTypes, (paymentTypeOption) => paymentTypeOption.value === paymentTypeFromSettings) ? paymentTypeFromSettings : 'scheduled';

                const paymentType = {
                    section: 'paymentInfo',
                    ref: React.createRef(),
                    label: 'Payment Type',
                    type: 'select',
                    options: paymentTypes,
                    value: paymentTypeValue,
                    onChange: this.onPaymentTypeChange,
                    validations: [checkRequired],
                    requestParameterPath: 'processingOptions.paymentType'
                };

                this.setState({ paymentType }, () => {
                    this.updateFormFields();
                });
            }
        }

        if (doesPropExistAndHasItChanged(prevProps, this.props, 'merchantList')) {
            this.updateFormFields();
        }

        if (doesPropExistAndHasItChanged(prevProps, this.props, 'paymentError')) {
            let message = 'Payment failed';

            if (_.includes(_.get(this.props, 'paymentError.body.message'), 'check3ds')) {
                message = 'This transaction went to cardholder authentication (3D Secure), which is not available for Virtual Terminal transactions.';
            }

            postMessage('error', this.props.paymentError);
            this.props.actionCompleted(message);

            if (!shouldHideToast) {
                toast(message, { type: 'error' });
            }
        }

        if (doesPropExistAndHasItChanged(prevProps, this.props, 'paymentSuccess')) {
            postMessage('success', this.props.paymentSuccess);

            const message = 'Payment was successful!';
            this.props.actionCompleted(message);

            if (!_.get(this.props, 'settings.uiOptions.shouldHideToast')) {
                toast(message, { type: 'success' });
            }
        }

        if (doesPropExistAndHasItChanged(prevProps, this.props, 'saveCardSuccess')) {
            const message = 'Save card was successful!';

            postMessage('cardSaved', this.props.saveCardSuccess);
            this.props.actionCompleted(message);

            if (!shouldHideToast) {
                toast(message, { type: 'success' });
            }
        }

        if (doesPropExistAndHasItChanged(prevProps, this.props, 'saveCardError')) {
            const message = 'Save card failed!';

            postMessage('error', this.props.saveCardError);
            this.props.actionCompleted(message);

            if (!shouldHideToast) {
                toast(message, { type: 'error' });
            }
        }

        this.props.onFormChange();
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.messageListener);
    }

    getFieldSettings = () => {
        const { uiOptions } = this.props.settings;
        const fields = _.get(uiOptions, 'fields');

        let fieldSettings;

        if (fields) {
            fieldSettings = _.cloneDeep(fields);
        } else {
            fieldSettings = buildFieldSettingsFromLegacyRequest(uiOptions, 'card');
        }

        const possibleGateways = findPossibleGatewayTypes(this.props.merchantId, this.props.currency, this.props.merchantList);

        if (possibleGateways.includes(gatewayType.openpay)) {
            fieldSettings.securityCode = 'required';
            fieldSettings.firstName = 'required';
            fieldSettings.email = 'required';
            fieldSettings.billToAddressOne = 'required';
            fieldSettings.billToCity = 'required';
            fieldSettings.billToState = 'required';
            fieldSettings.billToPostal = 'required';
            fieldSettings.billToCountry = 'required';
        }

        if (possibleGateways.includes(gatewayType.switch_kicc)) {
            fieldSettings.classification = 'required';
        }

        if (possibleGateways.includes(gatewayType.payments_os_colombia)) {
            fieldSettings.descriptor = 'required';
            fieldSettings.nationalIdentificationNumber = 'required';
            fieldSettings.orderNumber = 'required';
            fieldSettings.email = 'required';
            fieldSettings.shipToCountry = 'required';
            fieldSettings.billToCountry = 'required';
        }

        if (possibleGateways.includes(gatewayType.payments_os_asia_pacific)) {
            fieldSettings.email = 'required';
            fieldSettings.shipToCountry = 'required';
            fieldSettings.billToCountry = 'required';
        }

        if (possibleGateways.includes(gatewayType.reach)) {
            fieldSettings.firstName = 'required';
            fieldSettings.lastName = 'required';
            fieldSettings.email = 'required';
            fieldSettings.billToAddressOne = 'required';
            fieldSettings.billToCity = 'required';
            fieldSettings.billToCountry = 'required';
        }

        if (possibleGateways.includes(gatewayType.trust_my_travel)) {
            fieldSettings.securityCode = 'required';
            fieldSettings.firstName = 'required';
            fieldSettings.lastName = 'required';
            fieldSettings.email = 'required';
            fieldSettings.billToCountry = 'required';
            fieldSettings.orderDate = 'required';
        }

        return fieldSettings;
    };

    updateFormFields = async () => {
        const fieldSettings = this.getFieldSettings();

        const formFields = this.getFormFields();

        _.forEach(formFields, (field, fieldName) => {
            const fieldSetting = _.get(fieldSettings, _.last(_.split(field.requestParameterPath, '.')));

            const cardFields = ['cardHolderName', 'cardNumber', 'expirationDate'];
            if (this.props.paymentMethod === 'VAULT' && _.includes(cardFields, fieldName)) {
                field.shouldRender = false;

                field.validation = _.filter(field.validations, (val) => {
                    return val !== checkRequired;
                });
            } else if (this.props.isSaveCard && fieldName === 'amount') {
                field.shouldRender = false;
                field.validations = _.filter(field.validations, (val) => {
                    return val !== checkRequired;
                });
            } else if (fieldSetting === 'required' || _.includes(['amount', ...cardFields], fieldName)) {
                field.shouldRender = true;
                if (!_.includes(field.validations, checkRequired)) {
                    field.validations.push(checkRequired);
                }
            } else if (fieldSetting === 'optional') {
                field.shouldRender = true;

                field.validations = _.filter(field.validations, (val) => {
                    return val !== checkRequired;
                });
            } else {
                field.shouldRender = false;
                field.validations = _.filter(field.validations, (val) => {
                    return val !== checkRequired;
                });
            }
        });

        this.setState({ ...formFields, fieldSettings });
    };

    getFormFields = (isRenderedOnly) => {
        return _.reduce(this.state, (acc, value, key) => {

            if (_.get(value, 'section')) {
                if (!isRenderedOnly || _.get(value, 'shouldRender')) {
                    acc[key] = value;
                }
            }

            return acc;
        }, {});
    };

    messageListener = (event) => {
        if (event.data === 'posted') {
            this.submitButtonRef.current.click();
        }
    };

    onInputChange = (value, inputName) => {
        let newState = {
            [inputName]: {
                ...this.state[inputName],
                value
            }
        };

        if (_.startsWith(inputName, 'billing') && this.state.isShippingInfoSameAsBilling) {
            const shippingInputName = inputName.replace('billing', 'shipping');

            newState[shippingInputName] = {
                ...this.state[shippingInputName],
                value
            };
        }

        this.setState(newState, () => validateAll(this.getFormFields(true), true));
    };

    onSubmitForm = (e) => {
        e.preventDefault();

        const renderedFormFields = this.getFormFields(true);

        let isValid = validateAll(renderedFormFields);

        const { paymentMethod, vaultedCard } = this.props;

        if (paymentMethod === 'VAULT' && !vaultedCard) {
            isValid = false;
        }

        if (!isValid) {
            this.setState({
                hasSubmitError: true
            });

            return;
        } else {
            this.setState({
                hasSubmitError: false
            });

            const formData = _.mapValues(renderedFormFields, (field) => field.value);

            postMessage('submit', _.omit(formData, ['cardNumber']));

            let transactionBody = _.cloneDeep(this.props.settings);

            const paymentType = _.get(this.state, 'paymentType.value');

            _.set(transactionBody, 'processingOptions.paymentType', paymentMethod === 'VAULT' ? paymentType : 'initialMoto');

            if (vaultedCard) {
                _.set(transactionBody, 'tokenex.token', vaultedCard.tokenex.token);
            } else {
                const encryptedCardNumber = encryptValueForTokenex(formData.cardNumber);

                _.set(transactionBody, 'card.encryptedNumber', encryptedCardNumber);
                _.set(transactionBody, 'card.lastFour', formData.cardNumber.slice(-4));
                _.set(transactionBody, 'card.firstSix', formData.cardNumber.slice(0, 6));
                _.set(transactionBody, 'card.cardType', determineCardType(formData.cardNumber));
            }

            setIf(transactionBody, 'processingOptions.merchantId', this.props.merchantId);
            setIf(transactionBody, 'data.currency', this.props.currency || 'USD');

            const allFormFields = this.getFormFields();

            transactionBody = updateTransactionBodyWithFormData(transactionBody, allFormFields);

            if (this.props.isSaveCard) {
                this.props.saveCard(transactionBody);
            } else {
                this.props.processPayment(transactionBody);
            }
        }
    };

    onAuthOnlyChange = () => {
        this.setState({
            authOnly: {
                ...this.state.authOnly,
                value: !this.state.authOnly.value
            }
        }, () => validateAll(this.getFormFields(true), true));
    };

    onCardClassificationChange = (cardClassificationValue) => {
        const shouldRenderPersonalFields = cardClassificationValue === 'personal';
        const shouldRenderBusinessFields = cardClassificationValue === 'business';

        const updatedCardClassification = {
            ...this.state.cardClassification,
            value: cardClassificationValue
        };

        const updatedBusinessNumberInput = {
            ...this.state.businessNumber,
            shouldRender: shouldRenderBusinessFields
        };

        const updatedPasswordInput = {
            ...this.state.password,
            shouldRender: shouldRenderPersonalFields
        };

        const updatedBirthDateInput = {
            ...this.state.birthDate,
            shouldRender: shouldRenderPersonalFields
        };

        const possibleGateways = findPossibleGatewayTypes(this.props.merchantId, this.props.currency, this.props.merchantList);

        if (possibleGateways.includes(gatewayType.switch_kicc) && shouldRenderPersonalFields) {
            _.remove(updatedBusinessNumberInput.validations, checkRequired);
            updatedPasswordInput.validations.push(checkRequired);
            updatedBirthDateInput.validations.push(checkRequired);
        } else if (possibleGateways.includes(gatewayType.switch_kicc) && shouldRenderBusinessFields) {
            updatedBusinessNumberInput.validations.push(checkRequired);
            _.remove(updatedPasswordInput.validations, checkRequired);
            _.remove(updatedBirthDateInput.validations, checkRequired);
        }

        this.setState({
            cardClassification: updatedCardClassification,
            businessNumber: updatedBusinessNumberInput,
            password: updatedPasswordInput,
            birthDate: updatedBirthDateInput
        }, () => validateAll(this.getFormFields(true), true));
    };

    onCountryChange = async (countryInputValue, countryInputName, isInitialState = false) => {
        const country = getCountryByCountryCode(countryInputValue);

        const updatedCountryInput = {
            ...this.state[countryInputName],
            value: countryInputValue
        };

        const stateInputName = countryInputName === 'shippingCountry' ? 'shippingState' : 'billingState';
        const stateFieldName = countryInputName === 'shippingCountry' ? 'shipToState' : 'billToState';

        const updatedStateInput = {
            ...this.state[stateInputName],
            type: 'input',
            options: [],
            shouldRender: _.includes(['required', 'optional'], this.getFieldSettings()[stateFieldName])
        };

        if (_.get(country, 'states')) {
            updatedStateInput.options = country.states.map((state) => {
                return {
                    value: state.abbreviation,
                    name: state.name
                };
            });

            updatedStateInput.type = 'select';
        } else if (_.get(country, 'hasNoStates')) {
            updatedStateInput.shouldRender = false;
        }

        const cityFieldName = countryInputName === 'shippingCountry' ? 'shippingCity' : 'billingCity';
        const cityBasedState = {
            ...this.state[cityFieldName],
            type: 'input',
            shouldRender: _.includes(['required', 'optional'], this.getFieldSettings()[cityFieldName])
        };

        if (_.get(country, 'hasNoCities')) {
            cityBasedState.shouldRender = false;
        } else {
            cityBasedState.shouldRender = true;
        }

        if (!isInitialState) {
            updatedStateInput.value = '';
        }

        let newState = {
            [countryInputName]: updatedCountryInput,
            [stateInputName]: updatedStateInput,
            [cityFieldName]: cityBasedState
        };

        if (this.state.isShippingInfoSameAsBilling && _.startsWith(countryInputName, 'billing')) {
            const shippingCountryInputName = countryInputName.replace('billing', 'shipping');
            const shippingStateInputName = stateInputName.replace('billing', 'shipping');

            newState[shippingCountryInputName] = {
                ...this.state[shippingCountryInputName],
                value: countryInputValue
            };

            newState[shippingStateInputName] = {
                ...this.state[shippingStateInputName],
                type: updatedStateInput.type,
                shouldRender: updatedStateInput.shouldRender,
                options: updatedStateInput.options,
                value: updatedStateInput.value
            };

            newState[cityFieldName] = {
                ...this.state[cityFieldName],
                type: cityBasedState.type,
                shouldRender: cityBasedState.shouldRender
            };
        }

        this.setState(newState, () => validateAll(this.getFormFields(true), true));
    };

    onPaymentTypeChange = async (event) => {
        const paymentTypeClone = _.cloneDeep(this.state.paymentType);
        this.setState({ paymentType: { ...paymentTypeClone, value: event } }, () => validateAll(this.getFormFields(true), true));
    };

    onShippingInfoSameAsBillingChange = () => {
        const fields = this.getFormFields();
        const newState = _.reduce(fields, (acc, value, key) => {
            if (_.startsWith(key, 'shipping')) {
                const billingObj = this.state[key.replace('shipping', 'billing')];
                acc[key] = {
                    ...this.state[key],
                    isDisabled: !this.state.isShippingInfoSameAsBilling,
                    value: billingObj.value
                };

                if (key === 'shippingState') {
                    acc[key].type = billingObj.type;
                    acc[key].shouldRender = billingObj.shouldRender;
                    acc[key].options = billingObj.options;
                }
            }

            return acc;
        }, {
            isShippingInfoSameAsBilling: !this.state.isShippingInfoSameAsBilling
        });

        this.setState(newState, () => validateAll(this.getFormFields(true), true));
    };

    checkExpirationDate = (value) => {
        if (_.isEmpty(value)) {
            return '';
        } else if (_.size(value) !== 5 || !/\d\d\/\d\d/.test(value)) {
            return 'Invalid Date';
        } else {
            const expirationDate = moment(value, 'MM/YY').endOf('month');

            if (!expirationDate.isValid() || expirationDate.isBefore(moment(), 'month')) {
                return 'Invalid Date';
            }
        }

        return '';
    };

    checkSecurityCode = (value) => {
        if (_.isEmpty(value)) {
            return '';
        } else if (!/^\d{3,4}$/.test(value)) {
            return 'Must be between 3 and 4 digits';
        }
    };

    renderButton = () => {
        const { symbol = '$' } = _.get(currencyMapper, _.toLower(this.props.currency)) || {};
        const { isActionPending } = this.props;

        let amount = _.get(this.state, 'amount.value');

        if (!amount) {
            amount = '0.00';
        } else if (_.endsWith(amount, '.')) {
            amount = amount + '00';
        } else if (/\.\d$/.test(amount)) {
            amount = amount + '0';
        }

        let buttonText = `Pay ${symbol}${amount}`;

        if (this.props.isSaveCard) {
            buttonText = 'Save Card';
        }

        if (isActionPending) {
            buttonText = 'Submitted...';
        }

        return (
            <div>
                <button
                    className="submitButton"
                    type="submit"
                    ref={this.submitButtonRef}
                    disabled={isActionPending}
                >
                    {buttonText}
                </button>
                <span className="submitErrorMessage">{this.state.hasSubmitError ? 'Required field(s) missing or incorrect' : ''}</span>
            </div>
        );
    };

    renderSection = (section, sectionName) => {
        const { isActionPending } = this.props;
        let sectionSubSection = null;
        let sectionClassNames = `section ${sectionName}Section`;

        if (sectionName === 'shippingInfo') {
            sectionClassNames += this.state.isShippingInfoSameAsBilling ? ' sameAsBillings' : '';
            sectionSubSection = (
                <CheckBoxComponent
                    name="shippingInfoSameAsBilling"
                    label="Same as billing information"
                    checked={this.state.isShippingInfoSameAsBilling}
                    onClick={this.onShippingInfoSameAsBillingChange}
                />
            );
        }

        return (
            <div key={sectionName} className={sectionClassNames}>
                <div className="sectionHeader">{section.label}</div>
                {sectionSubSection}
                {_.map(section.fields, (field, fieldName) => {
                    if (field.shouldRender) {
                        const inputProps = {
                            key: fieldName,
                            name: fieldName,
                            ref: field.ref,
                            label: field.label,
                            value: field.value,
                            validations: field.validations,
                            disabled: isActionPending || field.isDisabled,
                            onChange: field.onChange || ((value) => this.onInputChange(value, fieldName))
                        };
                        if (field.type === 'date') {
                            return <InputComponent
                                type={field.type}
                                {...inputProps}
                            />;

                        } else if (field.type === 'select') {
                            return <SelectComponent
                                options={field.options}
                                {...inputProps}
                            />;
                        } else if (field.type === 'checkbox') {
                            return <CheckBoxComponent
                                ref={field.ref}
                                name={fieldName}
                                label={field.label}
                                checked={field.value}
                                onClick={field.onClick}
                            />;
                        } else {
                            const fieldsToNotValidateCardNumber = ['Card Number', 'Invoice', 'Customer Reference Number', 'Order Number'];

                            if (!_.includes(fieldsToNotValidateCardNumber, field.label)) {
                                inputProps.validations.push(checkDoesNotContainCreditCardLikeSubstring);
                            }

                            return <InputComponent
                                maxLength={field.maxLength}
                                placeholder={field.placeholder}
                                transformData={field.transformData}
                                {...inputProps}
                            />;
                        }
                    }

                    return null;
                })}
            </div>
        );
    };
    render = () => {

        return (
            <div className="keyedComponent">
                <form className="paymentForm" onSubmit={this.onSubmitForm}>
                    {renderSections(this.getFormFields(true), this.renderSection)}
                    {this.renderButton()}
                </form>
            </div>
        );
    };
}

KeyedComponent.propTypes = {
    paymentMethod: PropTypes.string.isRequired,
    processPayment: PropTypes.func.isRequired,
    saveCard: PropTypes.func.isRequired,
    paymentSuccess: PropTypes.object,
    paymentError: PropTypes.object,
    saveCardSuccess: PropTypes.object,
    saveCardError: PropTypes.object,
    settings: PropTypes.object,
    isSaveCard: PropTypes.bool,
    merchantId: PropTypes.string,
    currency: PropTypes.string,
    isActionPending: PropTypes.bool,
    actionCompleted: PropTypes.func.isRequired,
    isAuthOnly: PropTypes.bool,
    onFormChange: PropTypes.func.isRequired,
    merchantList: PropTypes.array,
    vaultedCard: PropTypes.any
};

const mapStateToProps = ({ keyed, app, merchant, customerVault }) => {
    return {
        paymentMethod: app.paymentMethod,
        paymentSuccess: keyed.paymentSuccess,
        paymentError: keyed.paymentError,
        saveCardSuccess: keyed.saveCardSuccess,
        saveCardError: keyed.saveCardError,
        settings: app.settings || {},
        isSaveCard: _.get(app, 'settings.uiOptions.saveCard'),
        merchantId: app.merchantId,
        currency: app.currency,
        isActionPending: app.isActionPending,
        merchantList: merchant.merchantList,
        vaultedCard: customerVault.card
    };
};

export default connect(mapStateToProps, {
    processPayment: actions.processPayment,
    saveCard: actions.saveCard,
    actionCompleted: appActions.actionCompleted
})(KeyedComponent);
