import _ from 'lodash';
import JSEncrypt from 'jsencrypt';

import config from '../../config/config';
import moment from 'moment';

export const setIf = (object, path, value) => {
    if (!_.isNil(value)) {
        _.set(object, path, value);
    }
};

export const retailIframeVersion = '0.0.1';

export function updateTransactionBodyWithFormData(transBody, formFields) {
    const transactionBody = _.cloneDeep(transBody);

    _.forEach(formFields, (field, fieldName) => {
        if (!_.isNil(field.value) && field.value !== '') {
            if (fieldName === 'expirationDate') {
                const [expirationMonth, expirationYear] = _.split(field.value, '/');

                setIf(transactionBody, 'card.expirationMonth', expirationMonth);
                setIf(transactionBody, 'card.expirationYear', expirationYear);
            }

            if (field.requestParameterPath) {
                setIf(transactionBody, field.requestParameterPath, field.value);
            }
        }
    });

    return transactionBody;
}

export const findPossibleGatewayTypes = (merchantId, currency, merchants) => {

    const { paymentOptions = [] } = _.find(merchants, { merchantId: merchantId }) || {};

    const gatewayTypes = paymentOptions
        .filter((paymentOption) => paymentOption.currency === currency)
        .map((paymentOption) => paymentOption.gateways)
        .flat()
        .map((gateway) => _.get(gateway, 'type'));

    return _.uniq(gatewayTypes);
};

export function isMerchantConfiguredForTerminalNmi(merchant) {
    return _.some(_.get(merchant, 'paymentOptions'), (option) => {
        return _.get(option, 'gateways[0].name') === 'nmi' && _.startsWith(_.get(option, 'gateways[0].label'), '-sk:');
    });
}

export function isMerchantConfiguredForTerminalUsaepay(merchant) {
    return _.some(_.get(merchant, 'paymentOptions'), (option) => {
        return _.get(option, 'gateways[0].name') === 'usaepay';
    });
}

export const findPossiblePaymentMethods = (merchantId, merchants) => {
    const { paymentOptions = [] } = _.find(merchants, { merchantId: merchantId }) || {};

    const paymentMethods = paymentOptions.map((paymentOption) => paymentOption.paymentMethod);

    return _.uniq(paymentMethods);
};

export const encryptValueForTokenex = (value) => {
    const jsEncrypt = new JSEncrypt();
    jsEncrypt.setKey(config.tokenex.publicEncryptionKey);

    return jsEncrypt.encrypt(value);
};
export const isCardExpired = (card) => {
    const expirationDate = `${_.padStart(card.expirationYear, 4, '20')}-${card.expirationMonth}`;

    return moment().isAfter(expirationDate, 'month');
};
