import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import SelectComponent from '../formComponents/select/selectComponent';

import { checkRequired } from '../formComponents/input/inputValidators';
import { actions as customerVaultActions } from './customerVaultActions';
import { actions as appActions } from '../appActions';
import { transformExpirationDate } from '../utils/formUtils';
import { isCardExpired } from '../utils/utils';

export class CustomerVaultComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: ''
        };
    }

    componentDidMount = () => {
        const options = this.getOptions();
        const selectedOption = _.find(options, (option) => {
            return !option.isDisabled  && option.value !== 'echeck' && option.value !== 'card';
        });
        if (!selectedOption) {
            return;
        }
        if (_.startsWith(selectedOption.value, 'card#')) {
            const token = selectedOption.value.replace('card#', '');
            this.props.getCustomerCardDetails(token);
        } else {
            const echeckIndex = selectedOption.value.replace('echeck#', '');
            const echeck = this.props.echecks[echeckIndex];
            this.props.setCustomerEcheck(echeck);
        }
        this.setState({
            value: selectedOption.value
        });
    }

    getOptions = () => {
        const combinedArray = [
            {
                value: 'card',
                name: 'Add New Card',
                isDisabled: false
            },
            {
                value: 'echeck',
                name: 'Add New eCheck',
                isDisabled: false
            }
        ];

        this.props.cards.filter((card) => {
            return card.expirationMonth && card.expirationYear && card.lastFour;
        }).map((card) => {
            const expirationDate = `${card.expirationMonth}/${card.expirationYear}`;

            combinedArray.push({
                value: `card#${card.token}`,
                name: `${_.toUpper(card.cardType)} ...${card.lastFour} (${transformExpirationDate(expirationDate)})`,
                title: isCardExpired(card) ? 'This card is expired' : '',
                isDisabled: isCardExpired(card)
            });
        });

        this.props.echecks.filter((echeck) => {
            return echeck.tokenex.lastFour && echeck.tokenex.token;
        }).map((echeck, index) => {
            combinedArray.push({
                value: `echeck#${index}`,
                name: `eCheck ....${echeck.tokenex.lastFour}`,
                isDisabled: false
            });
        });

        return _.orderBy(combinedArray, ['isDisabled']);
    };

    handleOnChange = (value) => {
        this.setState({ value });
        if (value === 'card') {
            this.props.updatePaymentMethod('KEYED');
        } else if (value === 'echeck') {
            this.props.updatePaymentMethod('ECHECK');
        } else {
            const valueArray = _.split(value, '#', 2);
            if (valueArray[0] === 'echeck') {
                this.props.setCustomerEcheck(this.props.echecks[valueArray[1]]);
            }
            if (valueArray[0] === 'card') {
                this.props.getCustomerCardDetails(valueArray[1]);
            }
        }
    };

    render() {
        return (
            <SelectComponent
                key="vaultSelector"
                name="vaultSelector"
                label="Saved Payment Methods"
                options={this.getOptions()}
                className="customerVaultSelector"
                onChange={this.handleOnChange}
                value={this.state.value}
                validations={[checkRequired]}
            />
        );
    }
}

CustomerVaultComponent.propTypes = {
    merchantId: PropTypes.string,
    cards: PropTypes.array,
    echecks: PropTypes.array,
    getCustomerCardDetails: PropTypes.func.isRequired,
    setCustomerEcheck: PropTypes.func.isRequired,
    updatePaymentMethod: PropTypes.func.isRequired
};

const mapStateToProps = ({ customerVault }) => {
    return {
        cards: customerVault.cards || [],
        echecks: customerVault.echecks || []
    };
};

export default connect(mapStateToProps, {
    getCustomerCardDetails: customerVaultActions.getCustomerCardDetails,
    setCustomerEcheck: customerVaultActions.setCustomerEcheck,
    updatePaymentMethod: appActions.updatePaymentMethod
})(CustomerVaultComponent);
