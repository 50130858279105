import _ from 'lodash';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import config from '../../config/config';
import { doAction } from '../middleware/fetchMiddleware';
import { actions as merchantActions } from '../merchant/merchantActions';
import { actions as terminalActions } from '../terminal/terminalActions';

export const storageKeys = {
    username: 'username',
    refreshToken: 'refreshToken',
    accessToken: 'accessToken',
    tokenExpiration: 'tokenExpiration'
};

const initialState = {
    isCheckingLogin: true,
    isLoggedIn: false
};

export const actionTypes = {
    LOGIN: 'login_login',
    LOGOUT: 'login_logout',
    SIMPLE_LOGIN: 'simple_login'
};

const createAwsCognitoUser = (username) => {
    return new AmazonCognitoIdentity.CognitoUser({
        Username: username,
        Pool: new AmazonCognitoIdentity.CognitoUserPool({
            UserPoolId: config.cognito.userPoolId,
            ClientId: config.cognito.clientId
        })
    });
};

const loginSuccess = (cognitoSession, dispatch) => {
    let authData = {
        username: cognitoSession.accessToken.payload.username,
        refreshToken: cognitoSession.refreshToken.token,
        accessToken: cognitoSession.idToken.jwtToken,
        tokenExpiration: cognitoSession.accessToken.payload.exp,
        access: JSON.parse(cognitoSession.idToken.payload['custom:accessRights'])
    };

    window.localStorage.setItem(storageKeys.username, authData.username);
    window.localStorage.setItem(storageKeys.refreshToken, authData.refreshToken);
    window.localStorage.setItem(storageKeys.accessToken, authData.accessToken);
    window.localStorage.setItem(storageKeys.tokenExpiration, authData.tokenExpiration);

    dispatch({
        type: actionTypes.LOGIN,
        payload: authData
    });
    dispatch(merchantActions.getMerchants());
    dispatch(merchantActions.getReportingMerchants());
    dispatch(terminalActions.getTerminals());
};

const loginError = (error, dispatch) => {
    const action = {
        type: doAction.error(actionTypes.LOGIN),
        payload: error
    };

    dispatch(action);
};

const login = (username, password) => (dispatch) => {
    const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
        Username: username,
        Password: password
    });
    const cognitoUser = createAwsCognitoUser(username);

    cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (results) => {
            loginSuccess(results, dispatch);
        },
        onFailure: (results) => {
            loginError(results.message, dispatch);
        }
    });
};

const logout = () => (dispatch) => {
    const username = window.localStorage.getItem(storageKeys.username);

    if (username) {
        const cognitoUser = createAwsCognitoUser(username);
        cognitoUser.signOut();
    }

    _.forEach(storageKeys, (value) => {
        window.localStorage.removeItem(value);
    });

    dispatch({
        type: actionTypes.LOGOUT
    });
};

const refresh = () => (dispatch) => {
    const username = window.localStorage.getItem(storageKeys.username);
    const refreshToken = window.localStorage.getItem(storageKeys.refreshToken);

    if (!username || !refreshToken) {
        dispatch(logout());

        return;
    }

    const cognitoUser = createAwsCognitoUser(username);
    const cognitoRefreshToken = new AmazonCognitoIdentity.CognitoRefreshToken({ RefreshToken: refreshToken });
    cognitoUser.refreshSession(cognitoRefreshToken, (error, session) => {
        if (error) {
            console.error('error', error);
            dispatch(logout());

            return;
        }

        loginSuccess(session, dispatch);
    });
};

const simpleLogin = (key) => (dispatch) => {
    dispatch({
        type: doAction.fetch(actionTypes.SIMPLE_LOGIN),
        fetchParams: {
            url: `${config.authApiUrl}/getSimpleLogin/${key}`,
            method: 'get'
        },
        onSuccess: refresh,
        onFail: logout
    });
};

export const actions = {
    login,
    logout,
    refresh,
    simpleLogin
};

export function reducers(state = initialState, action) {
    switch (action.type) {
        case actionTypes.LOGIN:
            return {
                ...state,
                ...action.payload,
                isCheckingLogin: false,
                isLoggedIn: true
            };
        case actionTypes.LOGOUT:
            return {
                ...initialState,
                isCheckingLogin: false
            };
        case doAction.success(actionTypes.SIMPLE_LOGIN):
            window.localStorage.setItem(storageKeys.username, action.payload.username);
            window.localStorage.setItem(storageKeys.refreshToken, action.payload.refreshToken);
            window.localStorage.setItem(storageKeys.accessToken, action.payload.jwt);

            return {
                ...state,
                ...action.payload
            };
        case doAction.error(actionTypes.LOGIN):
            return {
                ...initialState,
                isCheckingLogin: false,
                loginError: action.payload
            };

        default:
            return state;
    }
}
