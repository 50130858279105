import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import ToolTipIcon from '../../icons/toolTipIcon';
import './ToolTip.scss';

class ToolTip extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const toolTipContent = _.map(_.get(this.props, 'message'), (paragraph, index) => {
            return (
                <p key={`${paragraph.length}-${index}`}>{paragraph}</p>
            );
        });

        return (
            <div className="tooltip-container">
                <a data-tip data-for="toolTip"><ToolTipIcon/></a>
                <ReactTooltip className="react-tool-tip" id="toolTip" place="right" type="info" effect="solid">
                    <div>
                        {toolTipContent}
                    </div>
                </ReactTooltip>
            </div>
        );
    }
}

ToolTip.propTypes = {
    message: PropTypes.array.isRequired
};

export default ToolTip;
