import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { doesPropExistAndHasItChanged } from '@nexio/emvio-util-app';
import { toast } from 'react-toastify';

import { actions } from './loginActions';
import { postMessage } from '../utils/iframeUtils';
import InputComponent from '../formComponents/input/inputComponent';
import inputValidators from '../formComponents/input/inputValidators';

export class LoginComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.login = this.login.bind(this);
        this.getLoginComponent = this.getLoginComponent.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (doesPropExistAndHasItChanged(prevProps, this.props, 'loginError')) {
            postMessage('error', this.props.loginError);

            if (!this.props.shouldHideToast) {
                toast(this.props.loginError, { type: 'error' });
            }
        }
    }

    login(e) {
        e.preventDefault();

        const username = e.target.elements.username.value;
        const password = e.target.elements.password.value;

        this.props.login(username, password);
    }

    getLoginComponent() {
        return (
            <div className="loginComponent">
                <form onSubmit={this.login}>
                    <h1>Nexio Login</h1>
                    <InputComponent
                        autoFocus
                        name="username"
                        label="Username"
                        validations={[inputValidators.checkRequired]}
                    />
                    <InputComponent
                        name="password"
                        label="Password"
                        type="password"
                        validations={[inputValidators.checkRequired]}
                    />
                    <button type="submit">Log In</button>
                </form>
            </div>
        );
    }

    render() {
        if (this.props.isCheckingLogin) {
            return <div className="loginLoading" />;
        } else {
            return this.getLoginComponent();
        }
    }
}

LoginComponent.propTypes = {
    isCheckingLogin: PropTypes.bool.isRequired,
    login: PropTypes.func.isRequired,
    loginError: PropTypes.string,
    shouldHideToast: PropTypes.bool
};

const mapStateToProps = ({ login, app }) => {
    return {
        isCheckingLogin: login.isCheckingLogin,
        shouldHideToast: _.get(app, 'settings.uiOptions.shouldHideToast'),
        loginError: login.loginError
    };
};

export default connect(
    mapStateToProps,
    { login: actions.login }
)(LoginComponent);
