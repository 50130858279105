import _ from 'lodash';

import { storageKeys } from '../login/loginActions';

const actionSuffixes = {
    fetch: '--FETCH',
    success: '--SUCCESS',
    error: '--ERROR'
};

export const doAction = {
    fetch: (root) => root + actionSuffixes.fetch,
    success: (root) => root + actionSuffixes.success,
    error: (root) => root + actionSuffixes.error
};

const fetchMiddleware = ({ dispatch }) => (next) => (action) => {
    const actionType = action.type;
    const fetchParams = action.fetchParams || {};

    next(action);

    if (_.endsWith(actionType, actionSuffixes.fetch) && fetchParams.url) {
        const rootAction = _.replace(actionType, actionSuffixes.fetch, '');
        const accessToken = window.localStorage.getItem(storageKeys.accessToken);

        const fetchParams2 = _.merge({
            method: 'get',
            headers: {
                'content-type': 'application/json',
                authorization: accessToken
            }
        }, fetchParams);

        if (fetchParams2.body && !_.isString(fetchParams2.body)) {
            fetchParams2.body = JSON.stringify(fetchParams2.body);
        }

        return fetch(fetchParams.url, fetchParams2).then((response) => {
            if (!response.ok) {
                return response.json().then((body) => {
                    throw { statusCode: response.status, body };
                });
            }

            return response.json();
        }).then((response) => {
            dispatch({ type: doAction.success(rootAction), payload: response });
            handleResponseAction(action, 'onSuccess', dispatch, response);

            return response;
        }).catch((error) => {
            dispatch({ type: doAction.error(rootAction), payload: error });
            handleResponseAction(action, 'onFail', dispatch, error);

            throw error;
        });
    }
};

export function handleResponseAction(action, funcName, dispatch, response) {
    const func = action[funcName];
    if (_.isFunction(func)) {
        const result = func(action, response);
        if (_.isFunction(result)) {
            result(dispatch);
        }
    }
}

export default fetchMiddleware;
