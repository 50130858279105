import _ from 'lodash';
import config from '../../config/config';
import { doAction } from '../middleware/fetchMiddleware';
import { toast } from 'react-toastify';
import { postMessage } from '../utils/iframeUtils';

export const actionTypes = {
    GET_CUSTOMER_CARDS: 'getCustomerCards',
    GET_CUSTOMER_CARD_DETAILS: 'getCustomerCardDetails',
    GET_CUSTOMER_ECHECKS: 'getCustomerEchecks',
    SET_CUSTOMER_ECHECK: 'setCustomerEcheck'
};

const getCustomerCards = (customerRef) => (dispatch) => {
    dispatch({
        type: doAction.fetch(actionTypes.GET_CUSTOMER_CARDS),
        fetchParams: {
            url: `${config.cardServiceApiUrl}?limit=50&sort=-updatedAt&customerRef=${customerRef}`,
            method: 'get'
        },
        onFail: (action, error) => {
            postMessage('error', {
                ...action,
                message: error
            });
            toast('Unable to retrieve customer cards', { type: 'error' });
        }
    });
};

const getCustomerEchecks = (customerRef) => (dispatch) => {
    dispatch({
        type: doAction.fetch(actionTypes.GET_CUSTOMER_ECHECKS),
        fetchParams: {
            url: `${config.paymentApiUrl}/echeck?limit=500&customerRef=${customerRef}`,
            method: 'get'
        },
        onFail: (action, error) => {
            postMessage('error', {
                ...action,
                message: error
            });
            toast('Unable to fetch customer echecks', { type: 'error' });
        }
    });
};

const getCustomerCardDetails = (cardTokenexToken) => (dispatch) => {
    dispatch({
        type: doAction.fetch(actionTypes.GET_CUSTOMER_CARD_DETAILS),
        fetchParams: {
            url: `${config.paymentApiUrl}/vault/card/${cardTokenexToken}`,
            method: 'get'
        },
        onFail: (action, error) => {
            postMessage('error', {
                ...action,
                message: error
            });
            toast('Unable to retrieve customer card details', { type: 'error' });
        }
    });
};

const setCustomerEcheck = (echeck) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_CUSTOMER_ECHECK,
        echeck
    });
};

export const actions = {
    getCustomerCards,
    getCustomerCardDetails,
    getCustomerEchecks,
    setCustomerEcheck
};

export function reducers(state = {}, action) {
    switch (action.type) {
        case doAction.fetch(actionTypes.GET_CUSTOMER_CARDS):
            return {
                ...state,
                isCustomerCardsLoaded: false
            };
        case doAction.success(actionTypes.GET_CUSTOMER_CARDS):
            return {
                ...state,
                cards: _.get(action, 'payload.rows') || [],
                isCustomerCardsLoaded: true
            };
        case doAction.error(actionTypes.GET_CUSTOMER_CARDS):
            return {
                ...state,
                isCustomerCardsLoaded: true
            };
        case doAction.fetch(actionTypes.GET_CUSTOMER_ECHECKS):
            return {
                ...state,
                isCustomerEchecksLoaded: false
            };
        case doAction.success(actionTypes.GET_CUSTOMER_ECHECKS):
            return {
                ...state,
                echecks: _.get(action, 'payload.rows') || [],
                isCustomerEchecksLoaded: true
            };
        case doAction.error(actionTypes.GET_CUSTOMER_ECHECKS):
            return {
                ...state,
                isCustomerEchecksLoaded: true
            };
        case doAction.fetch(actionTypes.GET_CUSTOMER_CARD_DETAILS):
            return {
                ...state,
                isCustomerCardDetailsLoaded: false
            };
        case doAction.success(actionTypes.GET_CUSTOMER_CARD_DETAILS):
            return {
                ...state,
                card: action.payload,
                echeck: null,
                isCustomerCardDetailsLoaded: true
            };
        case doAction.error(actionTypes.GET_CUSTOMER_CARD_DETAILS):
            return {
                ...state,
                isCustomerCardDetailsLoaded: true
            };
        case actionTypes.SET_CUSTOMER_ECHECK:
            return {
                ...state,
                echeck: action.echeck,
                card: null
            };

        default:
            return state;
    }
}
