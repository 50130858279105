import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { doesPropExistAndHasItChanged, currency as currencyMapper, gatewayType } from '@nexio/emvio-util-app';

import { actions as appActions } from '../appActions';
import SelectComponent from '../formComponents/select/selectComponent';

import { isValidMerchantId } from '../utils/merchantUtils';
import { checkRequired } from '../formComponents/input/inputValidators';
import CustomerVaultComponent from '../customerVault/customerVaultComponent';
import { findPossibleGatewayTypes, findPossiblePaymentMethods } from '../utils/utils';

export class ActionSelector extends Component {
    constructor(props) {
        super(props);

        this.state = {
            paymentMethod: 'KEYED'
        };

        this.refList = {
            merchant: React.createRef(),
            currency: React.createRef()
        };
    }

    componentDidMount() {
        this.setSelectedMerchant();

        if (this.props.isSaveEcheck && this.props.paymentMethod !== 'ECHECK') {
            this.props.updatePaymentMethod('ECHECK');
        }
    }

    componentDidUpdate(prevProps) {
        if (doesPropExistAndHasItChanged(prevProps, this.props, 'merchantList') || doesPropExistAndHasItChanged(prevProps, this.props, 'terminalList')) {
            this.updateEnabledPaymentMethods();

            this.setSelectedMerchant();
        }

        if (doesPropExistAndHasItChanged(prevProps, this.props, 'merchantId')) {
            this.updateEnabledPaymentMethods();
        }

        if (this.props.isSaveCard && this.props.paymentMethod !== 'KEYED') {
            this.props.updatePaymentMethod('KEYED');
        }

        if (this.props.isSaveEcheck && this.props.paymentMethod !== 'ECHECK') {
            this.props.updatePaymentMethod('ECHECK');
        }

        this.props.onFormChange();
    }

    updateEnabledPaymentMethods = () => {
        const { merchantId, merchantList, paymentMethod, customerRef } = this.props;

        if (!merchantId || _.isEmpty(merchantList)) {
            return;
        }

        const possiblePaymentMethods = findPossiblePaymentMethods(merchantId, merchantList);

        const isCardMethodEnabled = _.includes(possiblePaymentMethods, 10);
        const isEcheckMethodEnabled = _.includes(possiblePaymentMethods, 40);
        const isTerminalMethodEnabled = this.doesSelectedMerchantHaveTerminals();
        const isVaultEnabled = customerRef && (isCardMethodEnabled || isEcheckMethodEnabled);

        this.setState({ isCardMethodEnabled, isEcheckMethodEnabled, isTerminalMethodEnabled, isVaultEnabled });

        if (!isCardMethodEnabled && !isEcheckMethodEnabled && isTerminalMethodEnabled) {
            this.props.updatePaymentMethod('TERMINAL');
        } else if (isCardMethodEnabled && !isEcheckMethodEnabled && paymentMethod === 'ECHECK') {
            this.props.updatePaymentMethod('KEYED');
        } else if (!isCardMethodEnabled && isEcheckMethodEnabled && paymentMethod === 'KEYED') {
            this.props.updatePaymentMethod('ECHECK');
        } else if (isCardMethodEnabled && !isTerminalMethodEnabled && paymentMethod === 'TERMINAL') {
            this.props.updatePaymentMethod('KEYED');
        } else if (isEcheckMethodEnabled && !isTerminalMethodEnabled && paymentMethod === 'TERMINAL') {
            this.props.updatePaymentMethod('ECHECK');
        }
    };

    getCurrenciesForSelectedPaymentMethod = () => {
        const {
            merchantList = [],
            merchantId,
            paymentMethod: selectedPaymentMethod,
            vaultedCard,
            vaultedEcheck
        } = this.props;

        if (selectedPaymentMethod === 'TERMINAL') {
            return ['USD'];
        }

        const { paymentOptions = [] } = merchantList.find((merc) => merc.merchantId === merchantId) || {};

        return paymentOptions
            .filter(({ paymentMethod }) => {
                return ((selectedPaymentMethod === 'KEYED' || vaultedCard) && paymentMethod === 10) ||
                    ((selectedPaymentMethod === 'ECHECK' || vaultedEcheck) && paymentMethod === 40);
            })
            .map(({ currency }) => currency);
    };

    setSelectedMerchant = () => {
        const { merchantList = [], merchantIdDefault, merchantIdSet } = this.props;

        let newMerchantId = merchantIdSet || merchantIdDefault;
        if (!isValidMerchantId(newMerchantId, merchantList)) {
            if (_.size(merchantList) === 1) {
                newMerchantId = merchantList[0].merchantId;
            } else {
                newMerchantId = '';
            }
        }

        if (newMerchantId) {
            this.props.updateMerchant(newMerchantId);
        }
    };

    renderMerchantList = () => {
        const { merchantId, merchantIdSet, merchantList = [], reportingMerchantList = [] } = this.props;

        if (_.size(merchantList) >= 1 && (!merchantIdSet || merchantIdSet !== merchantId)) {
            const options = merchantList.map((merchant) => {
                const reportingMerchant = _.find(reportingMerchantList, { merchantId: merchant.merchantId });
                const reportingMerchantName = _.get(reportingMerchant, 'name');

                return {
                    value: merchant.merchantId,
                    name: `${reportingMerchantName} - ${merchant.merchantId}`,
                    isDisabled: _.isEmpty(findPossiblePaymentMethods(merchant.merchantId, this.props.merchantList))
                };
            });

            return <SelectComponent
                className="merchantSelector"
                ref={this.refList.merchant}
                value={this.props.merchantId}
                options={options}
                name="merchant"
                label="Processing Account"
                onChange={this.props.updateMerchant}
                disabled={this.props.isActionPending}
                validations={[checkRequired]}
            />;

        }

        return null;
    };

    renderCurrencyList = () => {
        const { isSaveCard, isSaveEcheck } = this.props;

        if (isSaveCard || isSaveEcheck) {
            return;
        }

        let currencies = this.getCurrenciesForSelectedPaymentMethod();

        currencies = currencies.map((currency) => {
            const currencyObj = _.get(currencyMapper, _.toLower(currency)) || {};

            return {
                name: `${currency} - (${currencyObj.symbol})`,
                value: currency
            };
        });

        currencies = _.sortBy(_.uniqWith(currencies, _.isEqual), ['name']);

        const disabledTitle = _.isEmpty(currencies) ? 'No currencies supported for selected processing account' : '';

        return <SelectComponent
            className="currencySelector"
            ref={this.refList.currency}
            value={this.props.currency}
            options={currencies}
            name="currency"
            label="Currency"
            onChange={this.props.updateCurrency}
            title={disabledTitle}
            disabled={_.isEmpty(currencies) || this.props.isActionPending}
            validations={[checkRequired]}
        />;
    };

    /**
    * Checks if the selected merchant has terminals and valid access.
    *
    * @returns {boolean} Whether the selected merchant has terminals or valid gateway access.
    */
    doesSelectedMerchantHaveTerminals = () => {
        const { merchantId, terminalList, merchantList } = this.props;
        const selectedMerchantTerminals = _.filter(terminalList, { merchantId });

        const uniqueGatewayTypes = findPossibleGatewayTypes(merchantId, 'USD', merchantList);
        const possiblePaymentMethods = findPossiblePaymentMethods(merchantId, merchantList);
        const doesHaveAccessToUsaepay = _.includes(uniqueGatewayTypes, gatewayType.usaepay) && _.includes(possiblePaymentMethods, 10);
        const doesHaveAccessToNmi = _.includes(uniqueGatewayTypes, gatewayType.nmi) && _.includes(possiblePaymentMethods, 10);

        return doesHaveAccessToUsaepay || doesHaveAccessToNmi || !_.isEmpty(selectedMerchantTerminals);
    };

    renderPaymentMethod = () => {
        if (this.props.isSaveCard || this.props.isSaveEcheck) {
            return null;
        }

        let vaultDisabledMessage = '';
        if (!this.props.customerRef) {
            vaultDisabledMessage = 'No customer reference number provided';
        } else if (!this.state.isVaultEnabled) {
            vaultDisabledMessage = 'Selected processing account is not configured to run credit card or eCheck transactions';
        }

        const paymentMethods = [
            {
                value: 'KEYED',
                name: 'Card (keyed)',
                title: !this.state.isCardMethodEnabled ? 'Selected processing account is not configured to run credit card transactions' : '',
                isDisabled: !this.state.isCardMethodEnabled
            },
            {
                value: 'ECHECK',
                name: 'eCheck (keyed)',
                title: !this.state.isEcheckMethodEnabled ? 'Selected processing account is not configured to run eCheck transactions' : '',
                isDisabled: !this.state.isEcheckMethodEnabled
            },
            {
                value: 'TERMINAL',
                name: 'Card (terminal)',
                title: !this.state.isTerminalMethodEnabled ? 'No terminals available for selected processing account' : '',
                isDisabled: !this.state.isTerminalMethodEnabled
            },
            {
                value: 'VAULT',
                name: 'Saved Payment Methods',
                title: vaultDisabledMessage,
                isDisabled: !this.state.isVaultEnabled
            }
        ];

        return (
            <SelectComponent
                className="paymentMethodSelector"
                ref={this.refList.paymentMethod}
                value={this.props.paymentMethod}
                options={paymentMethods}
                name="paymentMethod"
                label="Payment Method"
                onChange={this.props.updatePaymentMethod}
                disabled={this.props.isActionPending || !this.props.merchantId}
                title={!this.props.merchantId ? 'No processing account selected' : ''}
                validations={[checkRequired]}
            />
        );
    };

    renderCustomerVault = () => {
        if (this.props.paymentMethod === 'VAULT') {
            return <CustomerVaultComponent/>;
        }
    };

    render = () => {
        return (
            <div className="actionSelector">
                {this.renderMerchantList()}
                {this.renderPaymentMethod()}
                {this.renderCustomerVault()}
                {this.renderCurrencyList()}
            </div>
        );
    };
}

ActionSelector.propTypes = {
    merchantId: PropTypes.string,
    merchantIdSet: PropTypes.string,
    merchantIdDefault: PropTypes.string,
    merchantList: PropTypes.array,
    reportingMerchantList: PropTypes.array,
    isSaveCard: PropTypes.bool,
    isSaveEcheck: PropTypes.bool,
    terminalList: PropTypes.array,
    updateMerchant: PropTypes.func,
    paymentMethod: PropTypes.string.isRequired,
    updatePaymentMethod: PropTypes.func.isRequired,
    currency: PropTypes.string,
    customerRef: PropTypes.string,
    updateCurrency: PropTypes.func.isRequired,
    isActionPending: PropTypes.bool,
    onFormChange: PropTypes.func.isRequired,
    vaultedEcheck: PropTypes.any,
    vaultedCard: PropTypes.any
};

function mapStateToProps({ app, terminal, merchant, customerVault }) {
    return {
        merchantIdSet: _.get(app, 'settings.uiOptions.merchantIdSet'),
        merchantIdDefault: _.get(app, 'settings.uiOptions.merchantIdDefault'),
        isSaveCard: _.get(app, 'settings.uiOptions.saveCard'),
        isSaveEcheck: _.get(app, 'settings.uiOptions.saveEcheck'),
        merchantList: merchant.merchantList,
        reportingMerchantList: merchant.reportingMerchantList,
        terminalList: terminal.terminalList,
        merchantId: app.merchantId,
        paymentMethod: app.paymentMethod,
        currency: app.currency,
        customerRef: _.get(app, 'settings.data.customer.customerRef'),
        isActionPending: app.isActionPending,
        vaultedCard: customerVault.card,
        vaultedEcheck: customerVault.echeck
    };
}

export default connect(mapStateToProps, {
    updateMerchant: appActions.updateMerchant,
    updateCurrency: appActions.updateCurrency,
    updatePaymentMethod: appActions.updatePaymentMethod
})(ActionSelector);
