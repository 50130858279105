import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { doesPropExistAndHasItChanged } from '@nexio/emvio-util-app';
import ReactTooltip from 'react-tooltip';
import ToolTipIcon from '../../icons/toolTipIcon';

import { createId } from '../../utils/uniqueId';
import { getValidationError } from '../../utils/formUtils';
import './inputComponent.scss';
import { checkRequired } from './inputValidators';

export class InputComponent extends Component {

    constructor(props) {
        super(props);

        let value = this.props.value || '';

        this.state = {
            id: this.props.id || createId('inputComponent'),
            defaultValue: value,
            value: value,
            hasValue: InputComponent.hasValue(value),
            isValid: true,
            isTouched: false,
            isFocused: false
        };

        this.validate = this.validate.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.focusHandler = this.focusHandler.bind(this);
        this.blurHandler = this.blurHandler.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (doesPropExistAndHasItChanged(prevProps, this.props, 'value') && this.state.value !== this.props.value) {
            this.setState({
                value: this.props.value
            }, () => {
                this.validate(true);
            });
        }
    }

    static hasValue(value) {
        return _.isNumber(value) || !_.isEmpty(value);
    }

    validate(softValidate) {
        const error = getValidationError(this.state.value, this.props.validations);

        if (!softValidate) {
            this.setState({
                isValid: !error,
                errorMsg: error,
                isTouched: true
            });
        }

        return !error;
    }

    changeHandler(e) {
        const transformData = this.props.transformData || _.identity;
        const value = transformData(e.target.value, this.state.value);

        this.setState({
            value: value,
            hasValue: InputComponent.hasValue(value),
            isTouched: true,
            isChanged: !_.isEqual(value, this.state.defaultValue)
        }, () => {
            this.validate();
            this.props.onChange && this.props.onChange(value);
        });
    }

    focusHandler() {
        this.setState({
            isTouched: true,
            isFocused: true
        });
    }

    blurHandler() {
        this.validate();

        this.setState({
            isFocused: false
        });
    }

    renderMessage() {
        const errorMsg = this.state.errorMsg;
        const hint = this.props.hint;

        const message = (this.state.isTouched && errorMsg) ? errorMsg : hint;

        return <div className="message">{message}</div>;
    }

    getComponentClasses() {
        return [
            'inputComponent',
            this.props.name,
            this.state.isValid ? 'valid' : 'invalid',
            this.state.isTouched ? 'touched' : 'untouched',
            this.state.isFocused ? 'focused' : 'unfocused',
            this.state.isChanged ? 'changed' : 'unchanged',
            this.state.hasValue ? 'populated' : 'unpopulated',
            this.props.disabled ? 'disabled' : 'enabled'
        ];
    }

    render() {
        const { validations, label, tooltip } = this.props;

        const inputLabel = <div className="input-label">
            {_.includes(validations, checkRequired) ? <span className="required-asterisk">*</span> : ''}
            {label}
            {tooltip ? <span data-tip data-for={`${this.state.id}-tooltip`}><ToolTipIcon /></span> : ''}
        </ div>;

        return (
            <div className={this.getComponentClasses().join(' ')}>
                <label htmlFor={this.state.id}>{inputLabel}</label>
                {tooltip ? <ReactTooltip
                    id={`${this.state.id}-tooltip`}
                    place="bottom"
                    type="info"
                    effect="float"
                    multiline>
                    {tooltip}
                </ReactTooltip>
                    :
                    null}
                <input
                    autoFocus={this.props.autoFocus}
                    id={this.state.id}
                    type={this.props.type || 'text'}
                    name={this.props.name}
                    value={this.state.value}
                    step={this.props.step}
                    maxLength={this.props.maxLength}
                    onChange={this.changeHandler}
                    onFocus={this.focusHandler}
                    onBlur={this.blurHandler}
                    disabled={!!this.props.disabled}
                    placeholder={this.props.placeholder}
                />
                {this.renderMessage()}
            </div>
        );
    }
}

InputComponent.propTypes = {
    autoFocus: PropTypes.bool,
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string,
    hint: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    maxLength: PropTypes.number,
    step: PropTypes.number,
    placeholder: PropTypes.string,
    validations: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.func),
        PropTypes.func
    ]),
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    transformData: PropTypes.func,
    tooltip: PropTypes.any
};

export default InputComponent;
