import React, { Component } from 'react';

export default class Check extends Component {
    render() {
        return (
            <svg
                version="1.1"
                className="icon"
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="14"
                viewBox="0 0 1406 1024"
            >
                <path d="M88.249 444.662l-85.588 83.473 483.615 495.865 917.062-940.538-85.598-83.462-831.475 852.761z"></path>
            </svg>
        );
    }
}
