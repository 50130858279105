import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { toast } from 'react-toastify';

import { doesPropExistAndHasItChanged, currency as currencyMapper } from '@nexio/emvio-util-app';

import {
    validateAll,
    getCountryByCountryCode,
    getCountryOptions,
    renderSections,
    transformAmount,
    transformInteger, buildFieldSettingsFromLegacyRequest
} from '../utils/formUtils';
import { encryptValueForTokenex, setIf, updateTransactionBodyWithFormData } from '../utils/utils';
import { postMessage } from '../utils/iframeUtils';
import { actions } from './echeckActions';
import { actions as appActions } from '../appActions';
import InputComponent from '../formComponents/input/inputComponent';
import {
    checkDoesNotContainCreditCardLikeSubstring,
    checkGreaterThanZero,
    checkOnlyTwoDecimalValues,
    checkPhone,
    checkRequired,
    checkAmountLessThanMax,
    checkAccountNumber,
    checkRoutingNumber,
    checkEmail,
    checkDateOfBirth
} from '../formComponents/input/inputValidators';
import SelectComponent from '../formComponents/select/selectComponent';
import CheckBoxComponent from '../formComponents/input/checkBoxComponent';

import './echeckComponent.scss';

export class ECheckComponent extends Component {
    constructor(props) {
        super(props);

        const { amountMax, amountSet, amountDefault, limitCountriesTo } = _.get(props, 'settings.uiOptions') || {};

        const combinedSettings = _.merge({}, this.props.settings, this.props.vaultedEcheck);

        let amount = '';
        if (amountSet) {
            amount = amountSet;
        } else if (amountDefault) {
            amount = amountDefault;
        }

        this.state = {
            isShippingInfoSameAsBilling: false,
            amount: {
                section: 'paymentInfo',
                ref: React.createRef(),
                label: 'Amount',
                value: amount,
                requestParameterPath: 'data.amount',
                onChange: this.onAmountChange,
                transformData: transformAmount,
                validations: [
                    checkDoesNotContainCreditCardLikeSubstring,
                    checkGreaterThanZero,
                    checkOnlyTwoDecimalValues,
                    checkRequired,
                    (value) => checkAmountLessThanMax(value, amountMax, amountSet)
                ],
                isDisabled: !!amountSet
            },
            accountHolderName: {
                section: 'echeckInfo',
                label: 'Name',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'bank.accountHolderName'),
                validations: [checkRequired],
                requestParameterPath: 'bank.accountHolderName'
            },
            accountNumber: {
                section: 'echeckInfo',
                label: 'Account Number',
                ref: React.createRef(),
                value: '',
                validations: [checkRequired, checkAccountNumber],
                transformData: transformInteger
            },
            routingNumber: {
                section: 'echeckInfo',
                label: 'Routing Number',
                ref: React.createRef(),
                value: '',
                validations: [checkRequired, checkRoutingNumber],
                transformData: transformInteger,
                requestParameterPath: 'bank.routingNumber'
            },
            billingCountry: {
                section: 'billingInfo',
                type: 'select',
                label: 'Country',
                ref: React.createRef(),
                options: getCountryOptions(limitCountriesTo),
                value: _.get(combinedSettings, 'data.customer.billToCountry'),
                validations: [],
                requestParameterPath: 'data.customer.billToCountry',
                onChange: (value) => this.onCountryChange(value, 'billingCountry')
            },
            billingAddressOne: {
                section: 'billingInfo',
                label: 'Address 1',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'data.customer.billToAddressOne'),
                validations: [],
                requestParameterPath: 'data.customer.billToAddressOne'
            },
            billingAddressTwo: {
                section: 'billingInfo',
                label: 'Address 2',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'data.customer.billToAddressTwo'),
                validations: [],
                requestParameterPath: 'data.customer.billToAddressTwo'
            },
            billingCity: {
                section: 'billingInfo',
                label: 'City',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'data.customer.billToCity'),
                validations: [],
                requestParameterPath: 'data.customer.billToCity'
            },
            billingState: {
                section: 'billingInfo',
                type: 'select',
                label: 'State / Province',
                ref: React.createRef(),
                options: [],
                value: _.get(combinedSettings, 'data.customer.billToState'),
                validations: [],
                requestParameterPath: 'data.customer.billToState'
            },
            billingPostalCode: {
                section: 'billingInfo',
                label: 'Postal Code',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'data.customer.billToPostal'),
                validations: [],
                requestParameterPath: 'data.customer.billToPostal'
            },
            billingPhone: {
                section: 'billingInfo',
                label: 'Phone',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'data.customer.billToPhone'),
                requestParameterPath: 'data.customer.billToPhone',
                validations: [checkPhone],
                transformData: transformInteger
            },
            shippingCountry: {
                section: 'shippingInfo',
                type: 'select',
                label: 'Country',
                ref: React.createRef(),
                options: getCountryOptions(limitCountriesTo),
                value: _.get(combinedSettings, 'data.customer.shipToCountry'),
                validations: [],
                requestParameterPath: 'data.customer.shipToCountry',
                onChange: (value) => this.onCountryChange(value, 'shippingCountry')
            },
            shippingAddressOne: {
                section: 'shippingInfo',
                label: 'Address 1',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'data.customer.shipToAddressOne'),
                validations: [],
                requestParameterPath: 'data.customer.shipToAddressOne'
            },
            shippingAddressTwo: {
                section: 'shippingInfo',
                label: 'Address 2',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'data.customer.shipToAddressTwo'),
                validations: [],
                requestParameterPath: 'data.customer.shipToAddressTwo'
            },
            shippingCity: {
                section: 'shippingInfo',
                label: 'City',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'data.customer.shipToCity'),
                validations: [],
                requestParameterPath: 'data.customer.shipToCity'
            },
            shippingState: {
                section: 'shippingInfo',
                type: 'select',
                label: 'State / Province',
                ref: React.createRef(),
                options: [],
                value: _.get(combinedSettings, 'data.customer.shipToState'),
                validations: [],
                requestParameterPath: 'data.customer.shipToState'
            },
            shippingPostalCode: {
                section: 'shippingInfo',
                label: 'Postal Code',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'data.customer.shipToPostal'),
                validations: [],
                requestParameterPath: 'data.customer.shipToPostal'
            },
            shippingPhone: {
                section: 'shippingInfo',
                label: 'Phone',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'data.customer.shipToPhone'),
                requestParameterPath: 'data.customer.shipToPhone',
                validations: [checkPhone],
                transformData: transformInteger
            },
            invoice: {
                section: 'orderInfo',
                label: 'Invoice',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'data.customer.invoice'),
                validations: [],
                requestParameterPath: 'data.customer.invoice'
            },
            orderNumber: {
                section: 'orderInfo',
                label: 'Order Number',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'data.customer.orderNumber'),
                validations: [],
                requestParameterPath: 'data.customer.orderNumber'
            },
            description: {
                section: 'orderInfo',
                label: 'Description',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'data.description'),
                validations: [],
                requestParameterPath: 'data.description'
            },
            descriptor: {
                section: 'orderInfo',
                label: 'Descriptor',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'data.descriptor'),
                validations: [],
                requestParameterPath: 'data.descriptor'
            },
            firstName: {
                section: 'customerInfo',
                label: 'First Name',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'data.customer.firstName'),
                validations: [],
                requestParameterPath: 'data.customer.firstName'
            },
            lastName: {
                section: 'customerInfo',
                label: 'Last Name',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'data.customer.lastName'),
                validations: [],
                requestParameterPath: 'data.customer.lastName'
            },
            phone: {
                section: 'customerInfo',
                label: 'Phone',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'data.customer.phone'),
                validations: [checkPhone],
                transformData: transformInteger,
                requestParameterPath: 'data.customer.phone'
            },
            email: {
                section: 'customerInfo',
                label: 'Email',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'data.customer.email'),
                validations: [checkEmail],
                requestParameterPath: 'data.customer.email'
            },
            customerRef: {
                section: 'customerInfo',
                label: 'Customer Reference Number',
                ref: React.createRef(),
                value: _.get(this.props, 'settings.data.customer.customerRef'),
                validations: [],
                requestParameterPath: 'data.customer.customerRef',
                isDisabled: this.props.paymentMethod === 'VAULT'
            },
            birthDate: {
                section: 'customerInfo',
                label: 'Date of Birth',
                placeholder: 'YYYY-MM-DD',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'data.customer.birthDate'),
                validations: [checkDateOfBirth],
                requestParameterPath: 'data.customer.birthDate'
            },
            nationalIdentificationNumber: {
                section: 'customerInfo',
                label: 'National Identification Number',
                ref: React.createRef(),
                value: _.get(combinedSettings, 'data.customer.nationalIdentificationNumber'),
                validations: [],
                requestParameterPath: 'data.customer.nationalIdentificationNumber'
            }
        };

        this.submitButtonRef = React.createRef();
    }

    async componentDidMount() {
        window.addEventListener('message', this.messageListener);

        await this.updateFormFields();

        if (this.state.billingCountry.shouldRender || this.state.billingState.shouldRender) {
            await this.onCountryChange(this.state.billingCountry.value, 'billingCountry', true);
        }

        if (this.state.shippingCountry.shouldRender || this.state.shippingState.shouldRender) {
            await this.onCountryChange(this.state.shippingCountry.value, 'shippingCountry', true);
        }
    }

    componentDidUpdate(prevProps) {
        const shouldHideToast = _.get(this.props, 'settings.uiOptions.shouldHideToast');

        if (doesPropExistAndHasItChanged(prevProps, this.props, 'paymentMethod')) {
            this.updateFormFields();
        }

        if (doesPropExistAndHasItChanged(prevProps, this.props, 'currency')) {
            this.updateFormFields();
        }

        if (doesPropExistAndHasItChanged(prevProps, this.props, 'paymentError')) {
            let message = 'Payment failed';

            postMessage('error', this.props.paymentError);
            this.props.actionCompleted(message);

            if (!shouldHideToast) {
                toast(message, { type: 'error' });
            }
        }

        if (doesPropExistAndHasItChanged(prevProps, this.props, 'paymentSuccess')) {
            postMessage('success', this.props.paymentSuccess);

            const message = 'Payment was successful!';
            this.props.actionCompleted(message);

            if (!_.get(this.props, 'settings.uiOptions.shouldHideToast')) {
                toast(message, { type: 'success' });
            }
        }

        if (doesPropExistAndHasItChanged(prevProps, this.props, 'saveECheckSuccess')) {
            const message = 'Save eCheck was successful!';

            postMessage('echeckSaved', this.props.saveECheckSuccess);
            this.props.actionCompleted(message);

            if (!shouldHideToast) {
                toast(message, { type: 'success' });
            }
        }

        if (doesPropExistAndHasItChanged(prevProps, this.props, 'saveECheckError')) {
            const message = 'Save eCheck failed!';

            postMessage('error', this.props.saveECheckError);
            this.props.actionCompleted(message);

            if (!shouldHideToast) {
                toast(message, { type: 'error' });
            }
        }

        this.props.onFormChange();
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.messageListener);
    }

    getFieldSettings = () => {
        const { uiOptions } = this.props.settings;
        const fieldSettings = _.get(uiOptions, 'fields');

        if (fieldSettings) {
            return _.cloneDeep(fieldSettings);
        } else {
            return buildFieldSettingsFromLegacyRequest(uiOptions);
        }
    };

    updateFormFields = async () => {
        const fieldSettings = this.getFieldSettings();

        const formFields = this.getFormFields();

        _.forEach(formFields, (field, fieldName) => {
            const fieldSetting = _.get(fieldSettings, _.last(_.split(field.requestParameterPath, '.')));

            const echeckFields = ['accountHolderName', 'routingNumber', 'accountNumber'];
            if (this.props.paymentMethod === 'VAULT' && _.includes(echeckFields, fieldName)) {
                field.shouldRender = false;

                field.validation = _.filter(field.validations, (val) => {
                    return val !== checkRequired;
                });
            } else if (this.props.isSaveEcheck && fieldName === 'amount') {
                field.shouldRender = false;
                field.validations = _.filter(field.validations, (val) => {
                    return val !== checkRequired;
                });
            } else if (fieldSetting === 'required' || _.includes(['amount', ...echeckFields], fieldName)) {
                field.shouldRender = true;
                if (!_.includes(field.validations, checkRequired)) {
                    field.validations.push(checkRequired);
                }
            } else if (fieldSetting === 'optional') {
                field.shouldRender = true;

                field.validations = _.filter(field.validations, (val) => {
                    return val !== checkRequired;
                });
            } else {
                field.shouldRender = false;
                field.validations = _.filter(field.validations, (val) => {
                    return val !== checkRequired;
                });
            }
        });

        this.setState({ ...formFields, fieldSettings });
    };

    getFormFields = (isRenderedOnly) => {
        return _.reduce(this.state, (acc, value, key) => {

            if (_.get(value, 'section')) {
                if (!isRenderedOnly || _.get(value, 'shouldRender')) {
                    acc[key] = value;
                }
            }

            return acc;
        }, {});
    };

    messageListener = (event) => {
        if (event.data === 'posted') {
            this.submitButtonRef.current.click();
        }
    };

    onInputChange = (value, inputName) => {
        let newState = {
            [inputName]: {
                ...this.state[inputName],
                value
            }
        };

        if (_.startsWith(inputName, 'billing') && this.state.isShippingInfoSameAsBilling) {
            const shippingInputName = inputName.replace('billing', 'shipping');

            newState[shippingInputName] = {
                ...this.state[shippingInputName],
                value
            };
        }

        this.setState(newState, () => validateAll(this.getFormFields(true), true));
    };

    onSubmitForm = async (e) => {
        e.preventDefault();

        const renderedFormFields = this.getFormFields(true);

        let isValid = validateAll(renderedFormFields);

        const { paymentMethod, vaultedEcheck } = this.props;

        if (paymentMethod === 'VAULT' && !vaultedEcheck) {
            isValid = false;
        }

        if (!isValid) {
            this.setState({
                hasSubmitError: true
            });
        } else {
            this.setState({
                hasSubmitError: false
            });

            const formData = _.mapValues(this.getFormFields(true), (field) => field.value);

            postMessage('submit', _.omit(formData, 'accountNumber'));

            let transactionBody = _.cloneDeep(this.props.settings);

            if (vaultedEcheck) {
                _.set(transactionBody, 'bank', vaultedEcheck.bank);
                _.set(transactionBody, 'tokenex.token', vaultedEcheck.tokenex.token);
            } else {
                const encryptedAccountNumber = encryptValueForTokenex(formData.accountNumber);

                _.set(transactionBody, 'bank.encryptedBankAccountNumber', encryptedAccountNumber);
            }

            setIf(transactionBody, 'processingOptions.merchantId', this.props.merchantId);
            setIf(transactionBody, 'data.currency', this.props.currency || 'USD');

            transactionBody = updateTransactionBodyWithFormData(transactionBody, this.getFormFields());

            if (this.props.isSaveEcheck) {
                this.props.saveEcheck(transactionBody);
            } else {
                this.props.processEcheck(transactionBody);
            }
        }
    };

    onCountryChange = async (countryInputValue, countryInputName, isInitialState = false) => {
        const country = getCountryByCountryCode(countryInputValue);

        const updatedCountryInput = {
            ...this.state[countryInputName],
            value: countryInputValue
        };

        const stateInputName = countryInputName === 'shippingCountry' ? 'shippingState' : 'billingState';
        const stateFieldName = countryInputName === 'shippingCountry' ? 'shipToState' : 'billToState';

        const updatedStateInput = {
            ...this.state[stateInputName],
            type: 'input',
            options: [],
            shouldRender: _.includes(['required', 'optional'], this.getFieldSettings()[stateFieldName])
        };

        if (_.get(country, 'states')) {
            updatedStateInput.options = country.states.map((state) => {
                return {
                    value: state.abbreviation,
                    name: state.name
                };
            });

            updatedStateInput.type = 'select';
        } else if (_.get(country, 'hasNoStates')) {
            updatedStateInput.shouldRender = false;
        }

        if (!isInitialState) {
            updatedStateInput.value = '';
        }

        let newState = {
            [countryInputName]: updatedCountryInput,
            [stateInputName]: updatedStateInput
        };

        if (this.state.isShippingInfoSameAsBilling && _.startsWith(countryInputName, 'billing')) {
            const shippingCountryInputName = countryInputName.replace('billing', 'shipping');
            const shippingStateInputName = stateInputName.replace('billing', 'shipping');

            newState[shippingCountryInputName] = {
                ...this.state[shippingCountryInputName],
                value: countryInputValue
            };

            newState[shippingStateInputName] = {
                ...this.state[shippingStateInputName],
                type: updatedStateInput.type,
                shouldRender: updatedStateInput.shouldRender,
                options: updatedStateInput.options,
                value: updatedStateInput.value
            };
        }

        this.setState(newState, () => validateAll(this.getFormFields(true), true));
    };

    onShippingInfoSameAsBillingChange = () => {
        const fields = this.getFormFields();
        const newState = _.reduce(fields, (acc, value, key) => {
            if (_.startsWith(key, 'shipping')) {
                const billingObj = this.state[key.replace('shipping', 'billing')];
                acc[key] = {
                    ...this.state[key],
                    isDisabled: !this.state.isShippingInfoSameAsBilling,
                    value: billingObj.value
                };

                if (key === 'shippingState') {
                    acc[key].type = billingObj.type;
                    acc[key].shouldRender = billingObj.shouldRender;
                    acc[key].options = billingObj.options;
                }
            }

            return acc;
        }, {
            isShippingInfoSameAsBilling: !this.state.isShippingInfoSameAsBilling
        });

        this.setState(newState, () => validateAll(this.getFormFields(true), true));
    };

    renderButton = () => {
        const { symbol = '$' } = _.get(currencyMapper, _.toLower(this.props.currency)) || {};
        const { isActionPending } = this.props;

        let amount = _.get(this.state, 'amount.value');

        if (!amount) {
            amount = '0.00';
        } else if (_.endsWith(amount, '.')) {
            amount = amount + '00';
        } else if (/\.\d$/.test(amount)) {
            amount = amount + '0';
        }

        let buttonText = `Pay ${symbol}${amount}`;
        if (this.props.isSaveEcheck) {
            buttonText = 'Save eCheck';
        }

        if (isActionPending) {
            buttonText = 'Submitted...';
        }

        return (
            <div>
                <button
                    className="submitButton"
                    type="submit"
                    ref={this.submitButtonRef}
                    disabled={isActionPending}
                >
                    {buttonText}
                </button>
                <span className="submitErrorMessage">{this.state.hasSubmitError ? 'Required field(s) missing or incorrect' : ''}</span>
            </div>
        );
    };

    renderSection = (section, sectionName) => {
        const { isActionPending } = this.props;
        let sectionSubSection = null;
        let sectionClassNames = `section ${sectionName}Section`;

        if (sectionName === 'shippingInfo') {
            sectionClassNames += this.state.isShippingInfoSameAsBilling ? ' sameAsBillings' : '';
            sectionSubSection = (
                <CheckBoxComponent
                    name="shippingInfoSameAsBilling"
                    label="Same as billing information"
                    checked={this.state.isShippingInfoSameAsBilling}
                    onClick={this.onShippingInfoSameAsBillingChange}
                />
            );
        }

        return (
            <div key={sectionName} className={sectionClassNames}>
                <div className="sectionHeader">{section.label}</div>
                {sectionSubSection}
                {_.map(section.fields, (field, fieldName) => {
                    if (field.customRenderer) {
                        return field.customRenderer();
                    } else if (field.shouldRender) {
                        const inputProps = {
                            key: fieldName,
                            name: fieldName,
                            ref: field.ref,
                            label: field.label,
                            value: field.value,
                            maxLength: field.maxLength,
                            placeholder: field.placeholder,
                            validations: field.validations,
                            disabled: isActionPending || field.isDisabled,
                            transformData: field.transformData,
                            onChange: field.onChange || ((value) => this.onInputChange(value, fieldName))
                        };

                        if (field.type === 'select') {
                            return <SelectComponent
                                {...inputProps}
                                options={field.options}
                            />;
                        } else if (field.type === 'checkbox') {
                            return <CheckBoxComponent
                                ref={field.ref}
                                key={fieldName}
                                name={fieldName}
                                label={field.label}
                                checked={field.value}
                                onClick={field.onClick}
                            />;
                        } else {
                            return <InputComponent
                                {...inputProps}
                            />;
                        }
                    }

                    return null;
                })}
            </div>
        );
    };

    render = () => {
        return (
            <div className="echeckComponent">
                <form className="paymentForm" onSubmit={this.onSubmitForm}>
                    {renderSections(this.getFormFields(true), this.renderSection)}
                    {this.renderButton()}
                </form>
            </div>
        );
    };
}

ECheckComponent.propTypes = {
    paymentMethod: PropTypes.string.isRequired,
    processEcheck: PropTypes.func.isRequired,
    saveEcheck: PropTypes.func.isRequired,
    paymentSuccess: PropTypes.object,
    paymentError: PropTypes.object,
    saveECheckSuccess: PropTypes.object,
    saveECheckError: PropTypes.object,
    settings: PropTypes.object,
    isSaveEcheck: PropTypes.bool,
    merchantId: PropTypes.string,
    currency: PropTypes.string,
    isActionPending: PropTypes.bool,
    actionCompleted: PropTypes.func.isRequired,
    isAuthOnly: PropTypes.bool,
    onFormChange: PropTypes.func.isRequired,
    merchantList: PropTypes.array,
    vaultedEcheck: PropTypes.any
};

const mapStateToProps = ({ echeck, app, merchant, customerVault }) => {
    return {
        paymentMethod: app.paymentMethod,
        paymentSuccess: echeck.paymentSuccess,
        paymentError: echeck.paymentError,
        saveECheckSuccess: echeck.saveECheckSuccess,
        saveECheckError: echeck.saveECheckError,
        settings: _.get(app, 'settings') || {},
        isSaveEcheck: _.get(app, 'settings.uiOptions.saveEcheck'),
        merchantId: app.merchantId,
        currency: _.get(app, 'settings.data.currency'),
        isActionPending: app.isActionPending,
        merchantList: merchant.merchantList,
        vaultedEcheck: customerVault.echeck
    };
};

export default connect(mapStateToProps, {
    processEcheck: actions.processECheck,
    saveEcheck: actions.saveECheck,
    actionCompleted: appActions.actionCompleted
})(ECheckComponent);
