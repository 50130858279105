import { toast } from 'react-toastify';
import config from '../../config/config';
import { doAction } from '../middleware/fetchMiddleware';
import { postMessage } from '../utils/iframeUtils';
import { actionTypes as appActionTypes } from '../appActions';
import { retailIframeVersion } from '../utils/utils';
import _ from 'lodash';

const PENDING_COUNT_MAX = 4;

const initialState = {
    terminalRequestPendingCount: PENDING_COUNT_MAX - 1,
    terminalRequestFetching: false
};

export const actionTypes = {
    GET_TERMINALS: 'getTerminals',
    CREATE_TERMINAL_REQUEST: 'createTerminalRequest',
    CHECK_TERMINAL_REQUEST: 'checkTerminalRequest',
    REGISTER_TERMINAL: 'registerTerminal',
    RESET_REGISTER_TERMINAL: 'resetRegisterTerminal',
    FETCH_PAIRING_CODE: 'fetchPairingCode',
    DELETE_PAIRING_CODE: 'deletePairingCode'
};

const deletePairingCode = () => ({
    type: actionTypes.DELETE_PAIRING_CODE
});

const fetchPairingCode = (terminalName, merchantId, paymentOptionTag) => (dispatch) => {
    const postBody = {
        merchantId,
        terminalName
    };
    if (paymentOptionTag) postBody.paymentOptionTag = paymentOptionTag;

    dispatch({
        type: doAction.fetch(actionTypes.FETCH_PAIRING_CODE),
        fetchParams: {
            url: `${config.paymentApiUrl}/pairTerminal`,
            method: 'post',
            body: postBody
        },
        onFail: (action, error) => {
            postMessage('error', {
                ...action,
                message: error
            });
            toast('Unable to retrieve pairing code', { type: 'error' });
        }
    });
};

const registerTerminal = (terminalName, merchantId, registrationCode, paymentOptionTag) => (dispatch) => {
    const postBody = {
        merchantId,
        terminalRegistrationCode: registrationCode,
        terminalName
    };
    if (paymentOptionTag) {
        _.set(postBody, 'paymentOptionTag', paymentOptionTag);
    }

    dispatch({
        type: doAction.fetch(actionTypes.REGISTER_TERMINAL),
        fetchParams: {
            url: `${config.paymentApiUrl}/registerTerminal`,
            method: 'post',
            body: postBody
        },
        onSuccess: () => {
            dispatch({
                type: doAction.fetch(actionTypes.GET_TERMINALS),
                fetchParams: {
                    url: `${config.paymentApiUrl}/getTerminalList`,
                    method: 'get'
                }
            });
        },
        onFail: (action, error) => {
            postMessage('error', {
                ...action,
                message: error
            });
            toast(_.get(error, 'body.message') || 'Unable to register terminal', { type: 'error' });
        }
    });
};

const resetRegisterTerminal = () => ({
    type: actionTypes.RESET_REGISTER_TERMINAL
});

const getTerminals = () => (dispatch) => {
    dispatch({
        type: doAction.fetch(actionTypes.GET_TERMINALS),
        fetchParams: {
            url: `${config.paymentApiUrl}/getTerminalList`,
            method: 'get'
        },
        onFail: (action, error) => {
            postMessage('error', {
                ...action,
                message: error
            });
            toast('User missing Payment Enabled Merchants', { type: 'error' });
        }
    });
};

const createTerminalRequest = (terminalRequestBody, merchantId) => (dispatch) => {
    postMessage('submit', terminalRequestBody);
    localStorage.setItem(`default-terminal-${merchantId}`, terminalRequestBody.terminalId);

    dispatch({
        type: doAction.fetch(actionTypes.CREATE_TERMINAL_REQUEST),
        fetchParams: {
            url: `${config.paymentApiUrl}/processFromTerminal`,
            method: 'post',
            body: terminalRequestBody,
            headers: {
                'X-NEXIO-REQUEST-SOURCE': `retailIframe#${retailIframeVersion}`
            }
        },
        onFail: (action, error) => {
            postMessage('error', {
                ...action,
                message: error
            });
            toast('Unable to send request to terminal', { type: 'error' });
        }
    });

    dispatch({ type: appActionTypes.ACTION_PENDING });
};

const checkTerminalRequest = (terminalRequestId) => (dispatch) => {
    dispatch({
        type: doAction.fetch(actionTypes.CHECK_TERMINAL_REQUEST),
        fetchParams: {
            url: `${config.paymentApiUrl}/processFromTerminal/${terminalRequestId}`,
            method: 'get'
        },
        onFail: (action, error) => {
            postMessage('error', {
                ...action,
                message: error
            });
            toast('Unable to read terminalRequest', { type: 'error' });
        }
    });
};

export const actions = {
    deletePairingCode,
    fetchPairingCode,
    registerTerminal,
    resetRegisterTerminal,
    getTerminals,
    createTerminalRequest,
    checkTerminalRequest
};

export function reducers(state = initialState, action) {
    switch (action.type) {
        case doAction.fetch(actionTypes.GET_TERMINALS):
            return {
                ...state,
                isTerminalListLoaded: false,
                keepCheckingForNewTerminal: false
            };
        case doAction.success(actionTypes.GET_TERMINALS):
            return {
                ...state,
                terminalList: action.payload || [],
                isTerminalListLoaded: true,
                keepCheckingForNewTerminal: true
            };
        case doAction.error(actionTypes.GET_TERMINALS):
            return {
                ...state,
                isTerminalListLoaded: false,
                keepCheckingForNewTerminal: false
            };
        case doAction.fetch(actionTypes.CREATE_TERMINAL_REQUEST):
            return {
                ...state,
                terminalRequest: action.payload,
                terminalRequestStatus: 'initializing'
            };
        case doAction.success(actionTypes.CREATE_TERMINAL_REQUEST):
            return {
                ...state,
                terminalRequest: action.payload,
                terminalRequestStatus: action.payload.terminalRequestStatus
            };
        case doAction.fetch(actionTypes.CHECK_TERMINAL_REQUEST):
            return {
                ...state,
                terminalRequestFetching: true
            };
        case doAction.success(actionTypes.CHECK_TERMINAL_REQUEST):
            return {
                ...state,
                terminalRequest: action.payload,
                terminalRequestStatus: action.payload.terminalRequestStatus,
                terminalRequestPendingCount: (state.terminalRequestPendingCount + 1) % PENDING_COUNT_MAX,
                terminalRequestFetching: false
            };
        case doAction.error(actionTypes.CHECK_TERMINAL_REQUEST):
            return {
                ...state,
                terminalRequestStatus: 'failed'
            };
        case doAction.fetch(actionTypes.FETCH_PAIRING_CODE):
            return {
                ...state,
                pairingCodeRequestFetching: true
            };
        case doAction.success(actionTypes.FETCH_PAIRING_CODE):
            return {
                ...state,
                pairingCode: action.payload.pairingCode,
                pairingCodeTerminalKey: action.payload.key,
                pairingCodeRequestFetching: false,
                keepCheckingForNewTerminal: true
            };
        case doAction.error(actionTypes.FETCH_PAIRING_CODE):
            return {
                ...state,
                pairingCodeRequestFetching: false
            };
        case actionTypes.DELETE_PAIRING_CODE:
            return {
                ...state,
                pairingCode: null,
                pairingCodeTerminalKey: null
            };
        case doAction.fetch(actionTypes.REGISTER_TERMINAL):
            return {
                ...state,
                isRegisteringTerminal: true
            };
        case doAction.success(actionTypes.REGISTER_TERMINAL):
            return {
                ...state,
                registeredTerminalKey: action.payload.terminalId,
                isRegisteringTerminal: false,
                registerTerminalSuccess: true
            };
        case doAction.error(actionTypes.REGISTER_TERMINAL):
            return {
                ...state,
                isRegisteringTerminal: false,
                registerTerminalSuccess: false
            };
        case actionTypes.RESET_REGISTER_TERMINAL:
            return {
                ...state,
                isRegisteringTerminal: null,
                registerTerminalSuccess: null,
                registeredTerminalKey: null
            };
        default:
            return state;
    }
}
