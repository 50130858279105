import React, { Component } from 'react';

export default class CaretDown extends Component {
    render() {
        return (
            <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="7"
                viewBox="0 0 1790 1024"
            >
                <path d="M-0.352 126.21l126.809-126.809 896.537 896.537-126.809 126.809-896.537-896.537z"></path>
                <path d="M767.826 896.581l896.537-896.537 126.809 126.809-896.537 896.537-126.809-126.809z"></path>
            </svg>
        );
    }
}
