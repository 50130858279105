import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';
import { ToastContainer } from 'react-toastify';

import App from './app';

ReactDOM.render(
    <Provider store={store}>
        <Fragment>
            <App />
            <ToastContainer
                className="toast-container"
                toastClassName="toast"
                autoClose={5000}
                closeOnClick={true}
                position="top-center"
            />
        </Fragment>
    </Provider>,
    document.getElementById('root'));
