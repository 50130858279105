import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { doesPropExistAndHasItChanged } from '@nexio/emvio-util-app';
import CaretDown from '../../icons/caretDown';
import ToolTip from '../../shared/toolTip/ToolTip';
import './selectComponent.scss';
import { getValidationError } from '../../utils/formUtils';
import { createId } from '../../utils/uniqueId';
import { checkRequired } from '../input/inputValidators';

export class SelectComponent extends Component {
    constructor(props) {
        super(props);

        const isRequired = _.includes(props.validations, checkRequired);

        let initialValue = '';
        if (this.isValueInListOfOptions(this.props.value)) {
            initialValue = props.value;
        } else if (isRequired && props.options.length === 1) {
            initialValue = props.options[0].value;
            this.props.onChange(initialValue);
        }

        this.state = {
            id: this.props.id || createId('selectComponent'),
            initialValue: initialValue,
            value: initialValue,
            isPopulated: !!props.value,
            isFocused: false,
            isTouched: false,
            isChanged: false,
            isValid: true,
            isRequired: isRequired
        };
    }

    componentDidUpdate(prevProps) {
        if (doesPropExistAndHasItChanged(prevProps, this.props, 'value')) {
            if (this.isValueInListOfOptions(this.props.value)) {
                this.updateValue(this.props.value);
            }
        }

        const isRequired = _.includes(this.props.validations, checkRequired);
        if (isRequired && !this.state.isRequired) {
            this.setState({ isRequired });
        } else if (!isRequired && this.state.isRequired) {
            this.setState({ isRequired });
        }

        if (doesPropExistAndHasItChanged(prevProps, this.props, 'options')) {
            const { options } = this.props;
            if (isRequired && _.size(options) === 1) {
                const value = options[0].value;

                this.updateValue(value);

                this.props.onChange(value);
            }
        }

        if (_.isEmpty(prevProps.options) && !_.isEmpty(this.props.options)) {
            const { value } = this.props;
            this.updateValue(value);
        }
    }

    isValueInListOfOptions = (value) => {
        const { options = [] } = this.props;

        return options.some((option) => option.value === value);
    };

    updateValue = (value) => {
        if (this.isValueInListOfOptions(value)) {
            this.setState({ value }, () => this.validate(true));
        }
    };

    validate = (isInitialValidation) => {
        const error = getValidationError(this.state.value, this.props.validations);

        this.setState((prevState) => {
            return {
                isValid: !error,
                errorMsg: error,
                isTouched: !isInitialValidation || prevState.isTouched
            };
        });

        return !error;
    }

    changeHandler = (e) => {
        _.invoke(e, 'persist');
        const newValue = e.target.value;

        this.setState({
            value: newValue,
            isTouched: true,
            isChanged: !_.isEqual(this.state.initialValue, newValue)
        }, () => {
            this.validate(),
            this.props.onChange && this.props.onChange(newValue);
        });
    }

    focusHandler = () => {
        this.setState({
            isFocused: true,
            isTouched: true
        });
    }

    blurHandler = () => {
        this.setState({
            isFocused: false
        });
    }

    getComponentCssClasses = () => {
        return [
            'selectComponent',
            this.state.isValid ? 'valid' : 'invalid',
            this.state.isTouched ? 'touched' : 'untouched',
            this.state.isFocused ? 'focused' : 'unfocused',
            this.state.value ? 'populated' : 'unpopulated',
            this.state.isChanged ? 'changed' : 'unchanged',
            this.props.disabled ? 'disabled' : 'enabled',
            this.props.className ? this.props.className : ''
        ];
    }

    renderMessage = () => {
        const errorMsg = this.state.errorMsg;
        const hint = this.props.hint;
        const messageText = (this.state.isTouched && errorMsg) ? errorMsg : hint;

        return <div className="message">{messageText}</div>;
    }

    render = () => {
        const options = [
            <option key={'blank'} value={''} disabled={this.state.isRequired}></option>,
            ...this.props.options.map((option) => {
                const key = _.isObject(option.value) ? JSON.stringify(option.value) : option.value;

                return (
                    <option
                        key={key}
                        value={option.value}
                        disabled={option.isDisabled}
                        title={option.title}
                    >{option.name}</option>
                );
            })
        ];

        const selectClasses = this.getComponentCssClasses();

        const { label } = this.props;

        const inputLabel = this.state.isRequired ?
            <span><span className="required-asterisk">*</span>{label}</span>
            : label;

        return (
            <div className={selectClasses.join(' ')}>
                <div className="selectCaret">
                    <CaretDown className="caret" />
                </div>
                <label htmlFor={this.state.id}>
                    {inputLabel}
                    <span>
                        {
                            this.props.toolTip ? <ToolTip message={this.props.toolTip} /> : null
                        }
                    </span>
                </label>
                <select
                    id={this.state.id}
                    name={this.props.name}
                    value={this.state.value}
                    onChange={this.changeHandler}
                    onFocus={this.focusHandler}
                    onBlur={this.blurHandler}
                    disabled={this.props.disabled}
                    title={this.props.title}
                >
                    {options}
                </select>
                {this.renderMessage()}
            </div>
        );
    }
}

SelectComponent.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    toolTip: PropTypes.array,
    value: PropTypes.any,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any,
            name: PropTypes.string
        })
    ),
    className: PropTypes.string,
    onChange: PropTypes.func,
    hint: PropTypes.string,
    validations: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.func),
        PropTypes.func
    ]),
    disabled: PropTypes.bool,
    title: PropTypes.string
};

export default SelectComponent;
